import React, { useState ,useEffect}  from "react";
import logo from '../assets/images/logo-light.png'
import search1 from '../assets/images/search-pic.png'
import icon from '../assets/icons/avatar.svg'

import accessibility from '../assets/icons/accessibility-light.svg'
import hamburger from '../assets/icons/hamburger.svg'
import searchdark from '../assets/icons/search-dark.svg'
import {useNavigate } from 'react-router-dom'
import searchIcon from '../assets/img/searchIcon.svg';
import forwardSingle from '../assets/img/forwardSingle.svg';
import forwardDouble from '../assets/img/forwardDouble.svg';
import back from '../assets/icons/back.svg'
import needHelp from "../assets/images/needHelp.svg"
import question from '../assets/icons/question.svg'
import Navrtl from "./NavBar.rtl";
import backArrow from '../assets/img/backArrow.svg'
import menuCross from "../assets/images/menuCross.svg"
import menuICon from "../assets/images/menuICon.png"
import icons8down48 from "../assets/images/icons8-down-48.png"
// import '../assets/styles/chapters/chapters.css';
import '../assets/styles/nav/index-hebrew.css'

import Footer from "./Footer";

export default function Chaptersrtl(){
    const navigate = useNavigate();

    const [chapters,setChapters] = useState([])  
    const [lastChapter,setLastChapter] = useState({sentenceText:";;"})   
    const [bookname,setBookname] = useState()  
    const [author,setAuthor] = useState()  
    const [filterchapters,setFilterChapters] = useState([])   
    const query = new URLSearchParams(window.location.search);
    const [search,setSearch] = useState('');
    const [originalChapters, setOriginalChapters] = useState([]);
    const [index, setIndex] =useState('')
    const [translate,setTransate] = useState()   

    const Profile=() =>{
        if(localStorage.role=="Admin" || localStorage.role=="Organization Manager")
        navigate('/institutertl')
        else
        navigate('/accountrtl')
    }
    const Logout= ()=>{
        localStorage.removeItem('loginDate')
        navigate('/loginrtl');
      }
    const Redirect=(restart, chapterId, bookdid ,index) =>{
        if(restart=="restart")
        navigate('/playerrtl?id='+chapterId+'&bookid='+bookdid+'&restart=true&index='+index)
        else
            navigate('/playerrtl?id='+chapterId+'&bookid='+bookdid+'&index='+index)
    }

    const Back=() =>{
        window.history.go(-1)
    }
    const showBooks =()=>{
        navigate('/booksrtl')
    }
    const Copyrights= ()=>{
        navigate('/Privacyrtl');
      }
      const Contacts= ()=>{
        navigate('/Contactsrtl');
      }
      const Help= ()=>{
        navigate('/Helprtl');
      }
    
    const splitChapters = (chapters) => {
        const groupedChapters = [];
        for (let i = 0; i < chapters.length; i += 5) {
            groupedChapters.push(chapters.slice(i, i + 5));
        }
        return groupedChapters;
    };

    const doSearch = (value) => {
        setSearch(value);
        if (value === "") {
            // Reset to original chapters when search input is cleared
            setFilterChapters(splitChapters(originalChapters));
            return;
        }
        let tmp = [];
        // Flatten the chapters array
        const flatChapters = chapters.flat();

        // Loop through the flattened array to search for the value
        for (let i = 0; i < flatChapters.length; i++) {
            if (flatChapters[i].chapterName.toLowerCase().includes(value.toLowerCase())) {
                tmp.push(flatChapters[i]);
            }
        }

        setFilterChapters(splitChapters(tmp));
    
    }
    const openMyChapter = (evt, tabName) => {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent2");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }
    function extractNumber(text) {
        const parts = text.split(';');
        for (const part of parts) {
          const match = part.match(/\d+/);
          if (match) {
            return match[0];
          }
        }
        return null; // Return null if no number is found
      }
      
    const getSearchParams=(k)=> {
        var p = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, k, v) { p[k] = v })
        return k ? p[k] : p;
    }

    useEffect(() => {

        //fetch('https://localhost:44318/api/UserSettings/GetReadupHe')
          fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
          .then(async response => {
              const data = await response.json();
              //debugger;
              setTransate(data);
              
          })
          .catch(error => {
              
          });
        const id = getSearchParams('id')

        
        
        fetch('https://api.readupp.com/api/Chapters/GetBookChapters?id='+id+'&user='+localStorage.userId)
    //    fetch('https://localhost:44318/api/Chapters/GetBookChapters?id='+id+'&user='+localStorage.userId)
        .then(async response => {
            const data = await response.json();
            const splitData = splitChapters(data);
            setOriginalChapters(data);  // Store the original chapters
             
            setChapters(splitData);
            setFilterChapters(splitData);
            console.log(filterchapters , chapters);
            localStorage.loginDate = Date.now()
        })
        .catch(error => {
            this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
        });
        
        fetch('https://api.readupp.com/api/Chapters/GetLastChapter?id='+id+'&user='+localStorage.userId)
       // fetch('https://localhost:44318/api/Chapters/GetLastChapter?id='+id+'&user='+localStorage.userId)
        .then(async response => {
            //debugger;
            const data = await response.json();
            setLastChapter(data);
            setBookname(localStorage.bookname)
            setAuthor(localStorage.author)
            localStorage.loginDate = Date.now()
            setIndex(extractNumber(data.sentenceText));
        })
        .catch(error => {
            setLastChapter({sentenceText:';;;',index:'0'});
        });



    },[])

    const showMenu=()=>{
        document.getElementById("mobileMenu").style.right ="0%";
    }
    const closeMenu=()=>{
        document.getElementById("mobileMenu").style.right ="-100%";
    }
    const showDropDown=()=>{
        document.getElementById("myDropdown").classList.toggle("show");
    }
    const id = getSearchParams('id')
    const lang= (val)=>{
        
        if(val==1)
        navigate('/chapters?id='+id);
        else
        navigate('/chaptersrtl?id='+id);
      }

      return( <>
        <Navrtl nav={'/chapters?id='+id} />
        <div className="mainContent2" dir="rtl">
            <div className="topHeader2 individualHeader">
                <div className="left">
                    <h2>
                        {bookname} <span>| {author}</span>
                    </h2>
                </div>
                <div className="right2">
                    <div className="progressLine">
                        <p>{lastChapter.index}% {translate != null ? translate[145].value : 'completed'}</p>
                        <div className="grayLine">
                            <div className="insideLine" style={{ width: lastChapter.index }}></div>
                        </div>
                    </div>
                    <div className="btn">
                        <h2>{translate != null ? translate[146].value : 'Chapter'} {index}</h2>
                        <button  onClick={() =>
                             Redirect(' ', lastChapter.chapterId, lastChapter.bookId, index -1)}
                        >{translate != null ? translate[147].value : 'Keep reading'}</button>
                    </div>
                </div>
                <div className="backPage">
                    <a href="./booksrtl" style={{ textDecoration: "none;" }}> <img src={backArrow} alt="חזור" /></a>
                </div>
            </div>
            <h2 id="chapterMobile">Chapters</h2>
            <div className="progressChapter" style={{ minHeight: '85vh' }}>
                <div className="search2">
                    <h2>{translate != null ? translate[144].value : 'Book Chapters'} </h2>
                    <div className="searchInput2">
                        <input className="input" type="text" name="Search" id="Search" placeholder={translate != null ? translate[92].value : 'Chapter Search'} onChange={(e) => doSearch(e.target.value)} />
                        <img src={searchIcon} alt="אייקון חיפוש" />
                    </div>
                </div>
                <div className="chapterList">
                    {filterchapters.map((group, index) => (
                        <button
                            id={index === 0 ? 'defaultOpen' : undefined}
                            className={`tablinks${index === 0 ? ' active' : ''}`}
                            onClick={(event) => openMyChapter(event, `chapter${index * 5 + 1}-${index * 5 + group.length}`)}
                            key={index}
                        >
                         {translate != null ? translate[56].value : 'Chapter'}     {index * 5 + 1}-{index * 5 + group.length}
                        </button>
                    ))}
                    {/* <img style={{ marginLeft: "26px" }} src={forwardSingle} alt="חץ בודד קדימה" />
                    <img style={{ marginLeft: "10px" }} src={forwardDouble} alt="חץ כפול קדימה" />
                   */}
                </div>
                <div className="contentTab2">

                    {filterchapters.map((group, index) => (
                        <div
                            id={`chapter${index * 5 + 1}-${index * 5 + group.length}`}
                            className="tabcontent2"
                            style={{ display: index === 0 ? 'block' : 'none' }}
                            key={index}
                        >
                            {group.map((chapter, chapterIndex) => {
                                const fullIndex = index * 5 + chapterIndex; // Calculate the full index


                                if (chapter.sttLines === "0%" || chapter.sttLines === "" || chapter.sttLines === "start") {
                                    return (
                                        <div className="row2" key={chapter.id}>
                                            <h2 className="readAgainText">{chapter.chapterName}</h2>
                                            <div className="progressBar">
                                                <div className="progressTopLine">
                                                    <div  className="progressInnerLine"></div>
                                                </div>
                                                <p className="completed"> 0% {translate != null ? translate[145].value : 'completed'}</p>
                                            </div>
                                            {/*style={{ width: "0%" }}  style={{ width: "0%" }}*/}
                                            <div className="description">
                                                <p >“{chapter.libraryId}…”</p>
                                            </div>
                                            <div className="btnsList">
                                                <button class="readLock colored" onClick={() => Redirect('restart', chapter.id, chapter.bookId,fullIndex)}>
                                                    {translate != null ? translate[131].value : 'Read'}</button>
                                            </div>
                                        </div>
                                    );
                                } else if (chapter.sttLines === "100%" || chapter.sttLines === "fin") {
                                    return (
                                        <div className="row2" key={chapter.id}>
                                            <h2 className="readAgainText">{chapter.chapterName}</h2>
                                            <div className="progressBar">
                                                <div className="progressTopLine">
                                                    <div style={{ width: "100%" }} className="progressInnerLine"></div>
                                                </div>
                                                <p className="completed">100% {translate != null ? translate[145].value : 'completed'}</p>
                                            </div>
                                            <div className="description">
                                                <p>“{chapter.libraryId}…”</p>
                                            </div>
                                            <div className="btnsList">
                                                <button className="readAgain" onClick={() => Redirect('restart', chapter.id, chapter.bookId,fullIndex)}>
                                                    {translate != null ? translate[101].value : 'Read Again'}</button>
                                            </div>
                                        </div>
                                    );
                                } else if (chapter.sttLines !== "100%" && chapter.sttLines !== "fin" && chapter.sttLines !== "0%" && chapter.sttLines !== "" && chapter.sttLines !== "start") {
                                    return (
                                        <div className="row2" key={chapter.id}>
                                            <h2 className="readAgainText">{chapter.chapterName}</h2>
                                            <div className="progressBar">
                                                <div className="progressTopLine">
                                                    <div style={{ width: chapter.sttLines }} className="progressInnerLine"></div>
                                                </div>
                                                <p className="completed"> {chapter.sttLines} {translate != null ? translate[145].value : 'completed'}</p>
                                            </div>
                                            <div className="description">
                                                <p>“{chapter.libraryId}…”</p>
                                            </div>
                                            <div className="btnsList">
                                                <button class="keepReading" onClick={() => Redirect(' ', chapter.id, chapter.bookId,fullIndex)}>
                                                    {translate != null ? translate[147].value : 'Keep reading'}</button>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    ))}


                </div>
            </div>
        </div>





        {/* <div className="chapterDetail">
            <div className="backBtn">
                <img
                    className="d-none d-lg-block back pointer" style={{ 'width': '50px', 'margin-left': '2%' }}
                    src={back} onClick={() => Back()}
                    alt="" />
            </div>
            <div className="inner" style={{ 'height': '200px' }}>
                <img style={{ 'float': 'right' }} src={search1} alt="" />
                <span style={{ 'float': 'left' }}>
                    <p style={{ "margin-bottom": "10px", 'text-align': 'left' }}>{author}
                    </p>
                    <div className="progressTop">
                        <h3>{bookname}</h3>
                        <div className="percentage">
                            <p style={{ "width": "90%" }}>{lastChapter.index}% completed</p>
                            <progress max="100" value={lastChapter.index}></progress>
                        </div>
                    </div>
                    <div className="chapter1">
                        <p>{lastChapter.sentenceText != "" && lastChapter.sentenceText.split(';')[2] != "" ? "“" + lastChapter.sentenceText.split(';')[2] + "...”" : ""}</p>
                        <button >{translate != null ? translate[10].value : 'Continue Reading'} </button>
                    </div>
                </span>
            </div>
        </div>
        <div className="readingPractice">
            <div className="headerTop">
                <h3 style={{ "margin-bottom": "0px" }}>{translate != null ? translate[10].value : 'Reading Practice'}</h3>
                <form action="#">
                    <input type="text" placeholder={translate != null ? translate[92].value : 'Chapter Search'} onChange={(e) => doSearch(e.target.value)} />
                    <img src={searchdark} alt="" />
                </form>
            </div>
            {filterchapters.map(chap => {
                if (chap.sttLines == "0%" || chap.sttLines == "" || chap.sttLines == "start") {
                    return (<>
                        <div className="chapterList">
                            <div className="detailLeft">
                                <h2>{chap.chapterName}</h2>
                                <div className="percentage">
                                    <p>0% Completed</p>
                                    <div className="progress">
                                        <progress className="w-100" max="100" value="0"></progress>
                                    </div>
                                </div>
                                <p className="title">“{chap.libraryId}…”</p>
                            </div>
                            <button style={{ 'display': localStorage.exp }} onClick={() => Redirect('restart', chap.id, chap.bookId)}>{translate != null ? translate[91].value : 'Start'}</button>
                        </div>
                    </>)
                }
                else if (chap.sttLines == "100%" || chap.sttLines == "fin") {
                    return (<>
                        <div className="chapterList">
                            <div className="detailLeft">
                                <h2>{chap.chapterName}</h2>
                                <div className="percentage">
                                    <p>Completed</p>
                                    <div className="progress">
                                        <span style={{ "width": "100%" }}></span>
                                    </div>
                                </div>
                                <p className="title"> “{chap.libraryId}…”</p>
                            </div>
                            <button className="f14" style={{ 'display': localStorage.exp }} onClick={() => Redirect('restart', chap.id, chap.bookId)}>{translate != null ? translate[101].value : 'Read Again'}</button>
                        </div>
                    </>)
                }
                else {
                    return (<>
                        <div className="chapterList">
                            <div className="detailLeft">
                                <h2>{chap.chapterName}</h2>
                                <div className="percentage">
                                    <p>{chap.sttLines} Completed</p>
                                    <div className="progress">
                                        <progress className="w-100" max="100" value={chap.sttLines.replace("%", "")}></progress>
                                    </div>
                                </div>
                                <p className="title">“{chap.libraryId}…”</p>
                            </div>
                            <button style={{ 'display': localStorage.exp }} onClick={() => Redirect('', chap.id, chap.bookId)}>{translate != null ? translate[86].value : 'Continue'}</button>
                            <button id="disableMobileButton" onClick={() => Redirect('restart', chap.id, chap.bookId)}>{translate != null ? translate[91].value : 'Start'}</button>
                        </div>
                    </>)
                }
            }
            )}



        </div> */}
      
  
  
      {/* <div class="chapterDetail" style={{'direction':'rtl'}}>
          <div class="backBtn">
          <img
                      class="d-none d-lg-block back pointer" style={{'width': '50px','margin-left': '2%'}}
                      src={back} onClick={() => Back()}
                      alt="" />
          </div>
          <div class="inner" style={{'height': '200px'}}>
          <img style={{'float': 'left'}} src={search1} alt="" />
          <span style={{'float': 'right'}}>
              <p style={{"margin-bottom": "10px",'text-align':'right'}}>{author}
              </p>
              <div class="progressTop">
                  <h3>{bookname}</h3>
                  <div class="percentage">
                      <p style={{"width": "90%"}}>{lastChapter.index}% הושלם</p>
                      <progress max="100" value={lastChapter.index}></progress>
                  </div>
              </div>
              <div class="chapter1">
                  <p>{lastChapter.sentenceText!="" && lastChapter.sentenceText.split(';')[2]!="" ? "“"+lastChapter.sentenceText.split(';')[2]+"...”": ""}</p>
                  <button> {translate!=null?translate[10].value:'המשך לקרוא '}</button>
              </div>
          </span>
          </div>
      </div>
      <div class="readingPractice" style={{'direction':'rtl'}}>
          <div class="headerTop" >
              <h3 style={{"margin-bottom": "0px"}}>{translate!=null?translate[10].value:'אימון קריאה'}</h3>
              <form action="#">
              <input  type="text" placeholder={translate!=null?translate[92].value:'Chapter Search'}  onChange={(e) => doSearch(e.target.value)}/>
              <img style={{'right':'80%'}} src={searchdark} alt=""/>
              </form>
          </div>
          {filterchapters.map(chap =>{
                          if(chap.sttLines=="0%" || chap.sttLines=="" || chap.sttLines=="start")
                          {
                              return(<>
                                  <div class="chapterList">
                                         <div class="detailLeft">
                                             <h2>{chap.chapterName}</h2>
                                             <div class="percentage">
                                                 <p>0% הושלם</p>
                                                 <div class="progress">
                                                     <progress class="w-100" max="100" value="0"></progress>
                                                 </div>
                                             </div>
                                             <p class="title">“{chap.libraryId}…”</p>
                                         </div>
                                         <button style={{'display':localStorage.exp}} onClick={() => Redirect('restart',chap.id,chap.bookId)}>{translate!=null?translate[91].value:'Start'}</button>
                                     </div>
                                 </>)
                          }
                          else if(chap.sttLines=="100%" || chap.sttLines=="fin")
                          {
                              return(<>
                               <div class="chapterList">
                                      <div class="detailLeft">
                                          <h2>{chap.chapterName}</h2>
                                          <div class="percentage">
                                              <p>הושלם</p>
                                              <div class="progress">
                                                  <span style={{"width": "100%"}}></span>
                                              </div>
                                          </div>
                                          <p class="title"> “{chap.libraryId}…”</p>
                                      </div>
                                      <button class="f14" style={{'display':localStorage.exp}} onClick={() => Redirect('restart',chap.id,chap.bookId)}> {translate!=null?translate[101].value:'קרא שוב '}</button>
                                  </div>
                              </>)
                          }
                          else 
                          {
                              return(<>
                               <div class="chapterList">
                                      <div class="detailLeft">
                                          <h2>{chap.chapterName}</h2>
                                          <div class="percentage">
                                              <p>{chap.sttLines} הושלם</p>
                                              <div class="progress">
                                              <progress class="w-100" max="100" value={chap.sttLines.replace("%","")}></progress>
                                              </div>
                                          </div>
                                          <p class="title">“{chap.libraryId}…”</p>
                                      </div>
                                      <button  style={{'display':localStorage.exp}} onClick={() => Redirect('',chap.id,chap.bookId)}>{translate!=null?translate[86].value:'Continue'}</button>
                                    <button id="disableMobileButton"   onClick={() => Redirect('restart',chap.id,chap.bookId)}>{translate!=null?translate[91].value:'Start'}</button>
                                  </div>
                              </>)
                          }
                      }
          )}
         
          
  
      </div> */}
 
          <Footer lang={'he'} />
          </>
  )
}



