import React, { useState, useEffect } from "react";
import bookimg from '../assets/images/books/oldbook.png'
import logo from '../assets/images/logo-light.png'
import search1 from '../assets/images/search-pic.png'
import icon from '../assets/icons/avatar.svg'
import searchIcon from '../assets/icons/search.svg'
import accessibility from '../assets/icons/accessibility-light.svg'
import hamburger from '../assets/icons/hamburger.svg'
import searchdark from '../assets/icons/search-dark.svg'
import upload1 from '../assets/img/upload 1.svg';
import speaker from '../assets/icons/speaker.svg'
import redCross from '../assets/img/redCross.svg';

import { useNavigate } from 'react-router-dom'
import formTop from "../assets/images/formTop.svg"
import cancelForm from "../assets/images/cancelForm.svg"
import errorIcon from "../assets/images/errorIcon.svg"
import mic from "../assets/images/mic.svg"
import Isolation_Mode from "../assets/images/Isolation_Mode.svg"
import crossFileChoose from "../assets/images/crossFileChoose.svg"
import deleteIcon from "../assets/images/deleteIcon.svg"
import delete1 from "../assets/images/delete.svg"
import arrowForward from '../assets/img/arrowForward.svg';
import cancel1 from "../assets/images/cancel1.svg"
import edit from "../assets/images/edit.svg"
import menuCross from "../assets/images/menuCross.svg"
import menuICon from "../assets/images/menuICon.png"
import imgUpload from "../assets/images/imgUpload.svg"
import needHelp from "../assets/images/needHelp.svg"
import icons8down48 from "../assets/images/icons8-down-48.png"
import question from '../assets/icons/question.svg'
import selectDown from '../assets/img/selectDown.svg';
import arrowDown from '../assets/img/arrowDown.svg';
import forwardSingle from '../assets/img/forwardSingle.svg';
import forwardDouble from '../assets/img/forwardDouble.svg';
import bookCover from '../assets/img/bookCover.png';
import speak from '../assets/img/speak.svg';
import readLock from '../assets/img/readLock.svg';
import cancel from '../assets/img/cancel.svg';
import Footer from './Footer';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Nav from "./NavBar";
import '../assets/styles/nav/home.css'
import axios from "axios";
import loader from '../assets/images/loader.gif'



export default function Books() {
    const navigate = useNavigate();

    const role = localStorage.getItem('role');

    const [msg, setMsg] = useState('');
    const [books, setBooks] = useState([])
    const [filterBooks, setFilterBooks] = useState([])
    const [search, setSearch] = useState('');
    const [language, setLanguage] = useState(' ');
    const [geners, setGeners] = useState([]);
    const [readinglevel, setReadingLevel] = useState([]);
    const [textlength, setTextLength] = useState([]);
    const [gener, setGenere] = useState();
    const [agegroup, setAgeGroup] = useState([]);
    const [chapterIndex, setChapterIndex] = useState()
    const [translate, setTransate] = useState()
    const [bookId, setbookId] = useState()
    const [showing, setShowing] = useState()
    const [showingpopup, setShowingPopUp] = useState()
    const [showingpopupload2, setShowingPopUpLoad2] = useState()
    const [showingpopupload1, setShowingPopUpLoad1] = useState()
    const [showSubMenu, setShowSubMenu] = useState()
    const [selectedBookId, setSelectedBookId] = useState(null);
    const [bookName, setBookName] = useState('')
    const [chapters, setChapters] = useState([])
    const [chaptersLength, setChapterLength] = useState();
    const [chapterId, setChapterId] = useState()
    const [showeditTextDetails, setEditTextDetails] = useState()
    const [showDelete, setShowDelete] = useState(false)
    const [firstName, setFirstName] = useState('');
    const [Libraries, setLibrary] = useState([])
    const [libararyedit, setLibararyedit] = useState([])
    const [lastChapter, setLastChapter] = useState({ sentenceText: ";;" })
    const [showloader, setshowloader] = useState()
    const [totalCount, setTotalCount] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10; // Set your items per page
    const [currentLibrary, setCurrentLibrary] = useState(null);
    const [exp, setExp] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [TitleValidation, setTitleValidation] = useState('');
    let [chapterList, setChapterList] = useState([1])

    const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);

    const [bookEdit, setBookEdit] = useState({
        id: "",
        bookName: "",
        authorName: "",
        langId: "",
        gener: "",
        library: "",
        narrationType: "",
        ageGroup: "",
        summery: "",
        image: "",
    });

    const [readinglevelupload, setReadingLevelUpload] = useState('');
    const [textlengthupload, setTextLengthUpload] = useState('');
    const [generupload, setGenereUpload] = useState('');
    const [agegroupupload, setAgeGroupUpload] = useState('');




    const readMore = (id, name, author) => {

        localStorage.setItem('currentPage', currentPage);
        localStorage.setItem('currentLibrary', currentLibrary);

        localStorage.loginDate = Date.now()
        localStorage.bookname = name;
        localStorage.author = author
        navigate('/chapters?id=' + id)

    }


    const showBooks = () => {
        navigate('/books')
    }
    const [fileChaptersName, setFileChaptersName] = useState([]);
    const [visibleChapters, setVisibleChapters] = useState([]);

    const AddChapterList = () => {
        const newChapterList = [...chapterList, chapterList.length + 1];
        const newVisibleChapters = [...visibleChapters, true]; // Add visibility state for the new chapter
        setChapterList(newChapterList);
        setVisibleChapters(newVisibleChapters);
    };

    // const AddChapterList = () => {

    //     //debugger;
    //     chapterList.push(1);
    //     let tmp = [...chapterList];
    //     setChapterList(tmp)


    // }
    const [currentLibraryIndex, setCurrentLibraryIndex] = useState(0);
    const [librariesPerPage, setLibrariesPerPage] = useState(5);

    const handleNextLibrary = () => {
        if (currentLibraryIndex + librariesPerPage < Libraries.length) {
            setCurrentLibraryIndex(currentLibraryIndex + 1);
        }
    };

    const handleNextLibrarySet = () => {
        if (currentLibraryIndex + librariesPerPage < Libraries.length) {
            setCurrentLibraryIndex(currentLibraryIndex + librariesPerPage);
        }
    };
    const handlePreviousLibrary = () => {
        if (currentLibraryIndex > 0) {
            setCurrentLibraryIndex(currentLibraryIndex - 1);
        }
    };

    const handlePreviousLibrarySet = () => {
        if (currentLibraryIndex - librariesPerPage >= 0) {
            setCurrentLibraryIndex(currentLibraryIndex - librariesPerPage);
        }
    };
    const visibleLibraries = Libraries.slice(currentLibraryIndex, currentLibraryIndex + librariesPerPage);



    const clickShowingPopUp = () => {

        closeMenu();
        setShowing(false)
        setShowingPopUp(!showingpopup);
        setShowing(!showing);
    }
    const closeTxtGeneratorModal = () => {

        setShowing(false)
        setShowingPopUp(false)
        setShowingPopUp(!showingpopup);
    }



    const clickShowingPopUpLoad1 = () => {

        closeMenu();
        setShowing(false)
        setShowingPopUp(false)
        setChapterList([])
        setShowingPopUpLoad1(!showingpopupload1);
        setShowingPopUpLoad2(false);
    }
    const clickShowingPopUpLoad2 = (is2) => {
        //debugger;
        setShowing(false)
        setShowingPopUp(false)
        document.getElementById('uploadfilebookname').value = document.getElementById('uploadfilebookname1').value;
        setChapterList([])

        if (is2)
            setShowingPopUpLoad1(!showingpopupload1);

        setShowingPopUpLoad2(!showingpopupload2);

    }
    const closeTxtGeneratorModalLoad2 = () => {

        setShowingPopUpLoad2(!showingpopupload2);
        setShowingPopUpLoad1(!showingpopupload1);
    }
    const closeTxtGeneratorModalLoad1 = () => {

        setShowingPopUpLoad1(!showingpopupload1);

    }

    const clickEditTextDetails = () => {

        setEditTextDetails(!showeditTextDetails);
    }
    const clickShowDelete = (index, id) => {
        setChapterId(id);
        setChapterIndex(index);
        setShowDelete(true);
    }







    const clickShowingSubMenu = (id) => {
        //debugger;
        for (var i = 0; i < document.getElementsByClassName('actionDropDown').length; i++) {
            if (document.getElementsByClassName('actionDropDown')[i].getAttribute('name') != id) {
                document.getElementsByClassName('actionDropDown')[i].style.display = 'none';
            }
        }
        if (document.getElementsByName(id)[0].style.display == 'none') {
            document.getElementsByName(id)[0].style.display = 'block';
        }
        else {
            document.getElementsByName(id)[0].style.display = 'none';
        }
        //setShowSubMenu( !showSubMenu );
    }






    const Profile = () => {

        if (localStorage.role == "Admin" || localStorage.role == "Organization Manager")
            navigate('/institute')
        else
            navigate('/account')
    }
    const ShowSummary = (that) => {

        if (document.getElementById(that.i).classList.contains('line')) {
            document.getElementById(that.i).classList.remove('line')
        }
        else {
            document.getElementById(that.i).classList.add('line')
        }

    }

    const Copyrights = () => {
        navigate('/Privacy');
    }
    const Contacts = () => {
        navigate('/Contacts');
    }
    const Help = () => {
        navigate('/Help');
    }

    // const SummeryAudio = (id) => {

    //     //  fetch('https://localhost:44318/api/Books/GetBookSummery?id='+id)
    //     fetch('https://api.readupp.com/api/Books/GetBookSummery?id=' + id)
    //         .then(async response => {
    //             //debugger;
    //             const data = await response.json();
    //             document.getElementById('carteSoudCtrl').src = "/Files/" + data.path;
    //             document.getElementById('carteSoudCtrl').load();
    //             document.getElementById('carteSoudCtrl').play();
    //         })
    //         .catch(error => {
    //             this.setState({ errorMessage: error.toString() });
    //             console.error('There was an error!', error);
    //         });



    // }
    const [playingAudioId, setPlayingAudioId] = useState(null);
    const SummeryAudio = (id) => {
        const audioElement = document.getElementById('carteSoudCtrl');

        // If the same audio is clicked again, pause it
        if (playingAudioId === id) {
            if (audioElement.paused) {
                audioElement.play();
            } else {
                audioElement.pause();
            }
        } else {
            // Fetch and play the new audio
            fetch('https://api.readupp.com/api/Books/GetBookSummery?id=' + id)
                .then(async (response) => {
                    const data = await response.json();
                    audioElement.src = "/Files/" + data.path;
                    audioElement.load();
                    audioElement.play();
                    setPlayingAudioId(id); // Set the new playing audio ID
                })
                .catch((error) => {
                    console.error('There was an error!', error);
                });
        }
    };
    const showMenu = () => {
        document.getElementById("mobileMenu").style.right = "0%";
    }
    const closeMenu = () => {
        document.getElementById("mobileMenu").style.right = "-100%";
    }
    const showDropDown = () => {
        document.getElementById("myDropdown").classList.toggle("show");
    }



    const NameAudio = (id) => {

        //  fetch('https://localhost:44318/api/Books/GetBookAudioName?id='+id)
        fetch('https://api.readupp.com/api/Books/GetBookAudioName?id=' + id)
            .then(async response => {

                const data = await response.json();
                document.getElementById('carteSoudCtrl').src = "/Files/" + data.path;
                document.getElementById('carteSoudCtrl').load();
                document.getElementById('carteSoudCtrl').play();
            })
            .catch(error => {
                this.setState({ errorMessage: error.toString() });
                console.error('There was an error!', error);
            });


    }
    const [file, setFile] = useState()
    const [filename, setFilename] = useState('');

    const [chaptersfile, setChaptersFile] = useState([])
    const [filechaptersname, setFileChaptersname] = useState([]);

    const onChangeHandler = (event) => {

        console.log(event.target.files[0]);
        setFile(event.target.files[0])
        setFilename(event.target.files[0].name);

    }

    const closeEditModal = () => {
        var editBookModal = document.getElementById("editBookModal");
        editBookModal.style.display = "none";
    }

    // delete modal
    const openDeleteModal = (bookId) => {
        let book = filterBooks.find(book => book.id === bookId);
        setBookName(book.bookName);
        setSelectedBookId(bookId)

        var deleteBookModal = document.getElementById("deleteBookModal");
        deleteBookModal.style.display = "block";
    }
    const closeDeleteModal = () => {
        var deleteBookModal = document.getElementById("deleteBookModal");
        deleteBookModal.style.display = "none";
    }

    // edit chapter modal
    const openEditChapterModal = (bookId) => {

        var editChapterModal = document.getElementById("editChapterModal");
        editChapterModal.style.display = "block";
        fetch('https://api.readupp.com/api/Chapters/GetBookChapters?id=' + bookId + '&user=' + localStorage.userId)
            //    fetch('https://localhost:44318/api/Chapters/GetBookChapters?id='+id+'&user='+localStorage.userId)
            .then(async response => {


                const data = await response.json();
                // const splitData = splitChapters(data);
                // setOriginalChapters(data);  // Store the original chapters
                setbookId(bookId)
                setChapters(data);
                setChapterLength(data.length);
                // setFilterChapters(splitData);
                console.log(chapters);
                localStorage.loginDate = Date.now()
            })
            .catch(error => {
                // this.setState({ errorMessage: error.toString() });
                // console.error('There was an error!', error);
            });

    }
    const closeEditChaperModal = () => {
        var editChapterModal = document.getElementById("editChapterModal");
        editChapterModal.style.display = "none";
    }

    const openEditModal = (bookId) => {
        let book = filterBooks.find(book => book.id === bookId);
        setBookEdit({
            id: book.id,
            title: book.bookName,
            author: book.authorName,
            language: book.langId,
            genre: book.gener != null ? book.gener : 1,
            library: book.libraryId,
            narrationType: book.voice,
            description: book.summery,
            bookCover: book.image,


        });

        console.log(bookEdit);
        var editBookModal = document.getElementById("editBookModal");
        editBookModal.style.display = "block";
    }
    // Get the element with id="defaultOpen" and click on it


    let defaultOpneLibarary = document.getElementById("defaultOpneLibarary");
    if (defaultOpneLibarary) {
        defaultOpneLibarary.click();
    }
    const openLibrary = (evt, libraryId, tabName) => {
        var i, tabcontent, tablinks;

        // Hide all tab content
        tabcontent = document.getElementsByClassName("tabcontent");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Remove "active" class from all tab links
        tablinks = document.getElementsByClassName("tablinks");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        if (tabName != null) {
            let tabElement = document.getElementById(tabName);
            if (tabElement) {
                tabElement.style.display = "block";
                evt.currentTarget.className += " active";
                setCurrentLibrary(libraryId);
                fetchBooks(libraryId, 1); // Fetch books for the first page of the selected library
                fetchTotalCount(libraryId);
            }
        }
    };


    const handlePageChange = (event, value) => {

        setCurrentPage(value);



    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };


    // Function to toggle dropdown visibility
    function toggleDropdown() {
        var dropdown = document.getElementById("dropdown");
        dropdown.classList.toggle("show");
    }
    const HandlerChap = (index) => {
        const newChapterList = chapterList.filter((_, i) => i !== index);
        const newFileChaptersName = fileChaptersName.filter((_, i) => i !== index);
        const newVisibleChapters = visibleChapters.filter((_, i) => i !== index);
        setChapterList(newChapterList);
        setFileChaptersName(newFileChaptersName);
        setVisibleChapters(newVisibleChapters);
    };

    const onChangeHandlerChap = (event, index) => {
        const files = [...fileChaptersName];
        files[index] = event.target.files[0].name;
        setFileChaptersName(files);
        chaptersfile.push(event.target.files[0]);
        let tmp = [...chaptersfile];
        setChaptersFile(tmp)
    };


    // const [isExpanded, setIsExpanded] = useState(false);

    // const truncateText = (text, length) => {
    //     if (text != null) {
    //         if (text.length > length) {
    //             return `${text.substring(0, length)}...`;
    //         }
    //     }
    //     return text;
    // };

    // const handleTextClick = (id) => {
    //   let book=  filterBooks.filter(x=>x.id === id)

    //     setIsExpanded((prevId) => (prevId === id ? null : id));

    // };


    const [expandedBookId, setExpandedBookId] = useState(null);

    const truncateText = (text, length) => {
        if (text != null) {
            if (text.length > length) {
                return `${text.substring(0, length)}...`;
            }
        }
        else text = ' ';
        return text;
    };

    const handleTextClick = (id) => {
        setExpandedBookId((prevId) => (prevId === id ? null : id));
    };

    const deleteChapter = async () => {
        try {
            const chapterToDelete = chapters.find(x => x.id === chapterId);
            if (!chapterToDelete) {
                throw new Error('Chapter not found');
            }
            const response = await fetch(`https://api.readupp.com/api/Chapters/DeleteCapter?id=${chapterId}&bookid=${chapterToDelete.bookId}`, {
                // const response = await fetch(`https://localhost:44318/api/Chapters/DeleteCapter?id=${chapterId}&bookid=${chapterToDelete.bookId}`, {
                method: 'POST'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const updatedChapters = chapters.filter(chapter => chapter.id !== chapterId);
            setChapters(updatedChapters);
            closeDeleteModal();
        } catch (error) {
            console.error('There was an error deleting the chapter!', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("Name:", name, "Value:", value); // Add this line to see the name and value
        setBookEdit({
            ...bookEdit,
            [name]: value
        });
    };

    function handleSubmit(event) {
        //debugger; 
        let name = "";
        let book = filterBooks.find(book => book.id === bookId);
        if (book.langId == 2) {
            name = "chapter ";
        }
        else {
            name = "פרק ";
        }
        event.preventDefault();
        // let bookname = document.getElementById('uploadfilebookname').value;
        let chapters = document.getElementsByClassName('chaptername');
        let chapterStr = '';
        let index = chaptersLength + 1
        for (var i = 0; i < chaptersfile.length; i++) {

            chapterStr += name + index + ';';
            index++;
        }


        //   const url = `https://localhost:44318/api/Books/UploadFiles`;
        const url = `https://api.readupp.com/api/Books/UploadFiles`;
        const formData = new FormData();
        // formData.append('bookName', bookname);
        formData.append('chaptersNames', chapterStr);
        formData.append('bookId', bookId);
        formData.append('userId', localStorage.userId)
        formData.append('onlyChapters', true)

        // formData.append('author', document.getElementById('author').value);
        // formData.append('undername', document.getElementById('undername').value);
        // formData.append('language', document.getElementById('language').value);
        // formData.append('agegroup', document.getElementById('agegroup').value);
        // formData.append('style', document.getElementById('style').value);
        // formData.append('voice', document.getElementById('voice').value);
        // formData.append('message', document.getElementById('message').value);


        chaptersfile.forEach((file, i) => formData.append(`chaptersFiles`, file));
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        setMsg('files uploaded...')
        setshowloader(true);
        axios.post(url, formData, config).then((response) => {
            console.log(response.data);
            if (response.status == 200) {
                setshowloader(false);
                setMsg(" Chapter add sucssefuly")
                chapterStr = '';
                setChaptersFile([]);
                setChapterList([])
                setFileChaptersName("")
                closeEditChaperModal();
                setMsg("")
            }


        });

    }

    const openMobileSearch = () => {
        var searchSection = document.getElementById("searchQuery");
        var arrowIcon = document.getElementById("arrow");

        if (
            searchSection.style.display === "none" ||
            searchSection.style.display === ""
        ) {
            searchSection.style.display = "block";
            arrowIcon.src = arrowDown; // Change the source of the image to the downward arrow
        } else {
            searchSection.style.display = "none";
            arrowIcon.src = arrowForward; // Change the source of the image back to the original arrow
        }
    }
    const Logout = () => {
        localStorage.removeItem('loginDate')
        navigate('/');
    }
    const lang = (val) => {
        //debugger;
        if (val == 1)
            navigate('/books');
        else
            navigate('/booksrtl');
    }
    const doSearch = async () => {
        let tmp = []
        const generInt = parseInt(gener, 10);

        for (var i = 0; i < books.length; i++) {
            if (books[i].bookName.toLowerCase().includes(search.toLowerCase()) && (gener == "" || gener == "0" || books[i].gener == generInt || gener == undefined) && (language == books[i].langId || language == " ")) {
                tmp.push(books[i]);
            }
        }

        if (tmp.length <= 0)
            try {
                const response = await fetch(`https://api.readupp.com/api/Books/SearchBooksByName?searchTerm=${search}`);
       
                //const response = await fetch(`https://localhost:44318/api/Books/SearchBooksByName?searchTerm=${search}`);
                const data = await response.json();
               data.forEach((book)=>{                  
                    tmp.push(book)
               })
               
                
            } catch (error) {
                console.error('There was an error search book!', error);

            }

            setFilterBooks(tmp);

    }
    const resetModal = (index, libraryName) => {
        const modal = document.querySelector(`[id="${libraryName}"]`);
        const modalContent = modal ? modal.getElementsByClassName("dropDownTable") : [];
        if (modalContent.length > 0) {
            Array.from(modalContent).forEach((content, i) => {
                if (i === index) {
                    content.style.display = content.style.display === "block" ? "none" : "block";
                } else {
                    content.style.display = "none";
                }
            });
        }
    };

    const deleteBook = async () => {
        try {
            const response = await fetch(`https://api.readupp.com/api/Books/Delete`, {
                //     const response = await fetch(`https://localhost:44318/api/Books/Delete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // Set the content type to JSON
                },
                body: selectedBookId.toString(), // Pass bookId as plain text
                // Pass bookId in the body as JSON
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            if (response.ok) {
                const updatedBooks = filterBooks.filter(book => book.id !== selectedBookId);
                setFilterBooks(updatedBooks);
                closeDeleteModal();

            }

        } catch (error) {

            console.error('There was an error deleting the book!', error);
        }
    };


    const validateTitle = (event) => {
        event.preventDefault();
        const title = document.getElementById('title');
        let isValid = true;

        if (!bookEdit.title) {

           setTitleValidation("1px solid red")
            isValid = false;
        }
        return isValid;
    };

    const editBook = (event) => {

        event.preventDefault();
        if (validateTitle(event) == false) {
            setErrorMessage('Mandatory fields please fill in.');
        }
        else {
            const url = ' https://api.readupp.com/api/Books/UpdateBook';
            //   const url = 'https://localhost:44318/api/Books/UpdateBook';
            const formData = new FormData();
            formData.append('bookId', bookEdit.id);
            formData.append('bookName', bookEdit.title);
            formData.append('library', bookEdit.library);
            formData.append('author', bookEdit.author);
            formData.append('language', bookEdit.language);
            if (bookEdit.gener == null) {
                bookEdit.gener = 1;
            }
            formData.append('genre', bookEdit.genre);
            formData.append('voice', bookEdit.narrationType);
            formData.append('message', bookEdit.description);
            formData.append('bookFile', file);
            formData.append('userId', localStorage.userId);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            axios.post(url, formData, config)
                .then((response) => {
                    console.log(response.data);
                    const updatedBook = response.data;

                    // Update filterBooks by creating a new array
                    const updatedFilterBooks = filterBooks.map((book) =>
                        book.id === updatedBook.id ? updatedBook : book
                    );

                    // Update the state with the new array
                    setFilterBooks(updatedFilterBooks);
                    closeEditModal();
                })
                .catch((error) => {
                    console.error("There was an error updating the book!", error);
                });
        }
    }

    const userId = localStorage.userId;

    const fetchBooks = async (libraryId, pageNumber) => {
        try {
            const response = await fetch(`https://api.readupp.com/api/Books/GetReadupBooksPagnation?id=${userId}&libarary=${libraryId}&pageNumber=${pageNumber}&pageSize=${itemsPerPage}`);

            //  const response = await fetch(`https://localhost:44318/api/Books/GetReadupBooksPagnation?id=${userId}&libarary=${libraryId}&pageNumber=${pageNumber}&pageSize=${itemsPerPage}`);
            const data = await response.json();
            setBooks(data);
            setFilterBooks(data);
            let num = parseInt(pageNumber)
            setCurrentPage(num)
            localStorage.removeItem('currentPage');
            localStorage.removeItem('currentLibrary');


        } catch (error) {
            console.error('There was an error fetching books!', error);
        }
    };

    const fetchTotalCount = async (libraryId) => {

        try {

            //  const response = await fetch(`https://localhost:44318/api/Books/GetCountBooks?id=${userId}&libraryId=${libraryId}`);
          const response = await fetch(`https://api.readupp.com/api/Books/GetCountBooks?id=${userId}&libraryId=${libraryId}`);
            const data = await response.json();
            setTotalCount(data);
            setTotalPages(Math.ceil(data / itemsPerPage));
        } catch (error) {
            console.error('There was an error fetching total count!', error);
        }
    };

    useEffect(() => {

        const userId = localStorage.userId;

        debugger;
        if(localStorage.exp!="none" && localStorage.exp!="exp")
            setExp(true)

        // Get saved state or default to first page and first library
        // const savedPage = parseInt(localStorage.getItem('currentPage'), 10) || 1;
        // const savedLibrary = parseInt(localStorage.getItem('currentLibrary'), 10) || 1;

        // Set current page and library
        // setCurrentPage(savedPage);
        // setCurrentLibrary(savedLibrary);

        // Clear saved state
        // localStorage.removeItem('currentPage');
        // localStorage.removeItem('currentLibrary');




        fetch('https://api.readupp.com/getAllLibrarys?Id=' + localStorage.userId)
            //  fetch('https://localhost:44318/getAllLibrarys?Id=' + localStorage.userId)
            .then(async response => {
                const data = await response.json();
                setLibrary(data);

                const libraryId = localStorage.currentLibrary ? localStorage.currentLibrary : 1;
                const page = localStorage.currentPage ? localStorage.currentPage : currentPage;
                //   if (libraryId) {



                if (Array.isArray(data)) {
                    if (role !== 'Admin' && role !== 'Organization Manager' && role !== "learning central managermanger") {
                        const libraryFilter = data.filter(library => {
                            const libraryUserId = parseInt(library.userId, 10);
                            console.log('Library userId:', libraryUserId, 'LocalStorage userId:', localStorageUserId);
                            return libraryUserId === localStorageUserId && library.visibility === 1;
                        });
                        console.log('Filtered libraries:', libraryFilter);
                        setLibararyedit(libraryFilter);
                    }
                    else {
                        setLibararyedit(data)
                    }
                }
                if (currentLibrary == null) {
                    fetchBooks(1, page); // Fetch books for the saved or first library
                    fetchTotalCount(1); // Fetch total count for the saved or first library
                }
                else {
                    fetchBooks(currentLibrary, page); // Fetch books for the saved or first library
                    fetchTotalCount(currentLibrary); // Fetch total count for the saved or first library
                }

            })
            .catch(error => {
                console.error('There was an error fetching user settings!', error);
            });

        // Fetch other data as needed
        fetch('https://api.readupp.com/api/UserSettings/GetReadupEn')
            .then(async response => {
                const data = await response.json();
                setTransate(data);
            })
            .catch(error => {
                console.error('There was an error fetching user settings!', error);
            });

        fetch('https://api.readupp.com/api/Genre/GetGenres')
            .then(async response => {
                const data = await response.json();
                setGeners(data);
            })
            .catch(error => {
                console.error('There was an error fetching genres!', error);
            });

        fetch('https://api.readupp.com/api/ReadLevel/GetReadingLevels')
            .then(async response => {
                const data = await response.json();
                setReadingLevel(data);
            })
            .catch(error => {
                console.error('There was an error fetching reading levels!', error);
            });

        fetch('https://api.readupp.com/api/TextLength/GetTextLength')
            .then(async response => {
                const data = await response.json();
                setTextLength(data);
            })
            .catch(error => {
                console.error('There was an error fetching text length!', error);
            });

        fetch('https://api.readupp.com/api/AgeGroup/GetAgeGroup')
            .then(async response => {
                const data = await response.json();
                setAgeGroup(data);
            })
            .catch(error => {
                console.error('There was an error fetching age groups!', error);
            });

        fetch('https://api.readupp.com/Login/GetUser?userid=' + localStorage.userId)
            //  fetch('https://localhost:44318/Login/GetUser?userid='+localStorage.userId)
            .then(async response => {
                const data = await response.json();
                setFirstName(data.firstName)
            })
            .catch(error => {

                console.error('There was an error!', error);
            });



        setChapterList(chapterList);
        setshowloader(false);
    }, [currentPage, itemsPerPage]);


    // useEffect(() => {

    //     if (currentLibrary) {
    //         fetchBooks(currentLibrary, currentPage);
    //         fetchTotalCount(currentLibrary);
    //     }
    // }, [currentPage, currentLibrary, itemsPerPage]);
    const startingChapterNumber = chapters.length + 1;



    return (
        <>
            <Nav nav="booksrtl" />

            <div className="mainContent bookMobileColor" dir="ltr">
                {/* {(role !== 'Admin' && role !== 'Organization Manager') && (
                    <div className="topHeader">
                        <div className="left">
                            <h2>Hi, {firstName}</h2>
                            <p>So far you read 4 books</p>
                        </div>
                        <div className="right">
                            <div className="text">
                                <h2>Continue reading:</h2>
                                <p>{lastChapter.sentenceText}</p>
                            </div>
                            <button>Continue reading</button>
                        </div>
                    </div>
                )} */}

                {/* {(role === 'Admin' || role === 'Organization Manager') && ( */}
                <div className="topHeader">
                    <div className="left">
                        <h2 style={{ fontSize: '28px', lineHeight: '24px', fontWeight: 700 }}>Books</h2>
                    </div>
                    <div className="text"></div>
                </div>
                {/* )} */}

                <div className="topContent" style={{ minHeight: '85vh' }}>
                    <div className="search">
                        <h2>Book search:</h2>
                        <div className="custom-select">
                            <select name="" id="" onChange={(e) => setGenere(e.target.value)}>
                                <option value="0">{translate != null ? translate[89].value : 'Genre'}</option>
                                {geners.map(g => (
                                    <option key={g.id} value={g.id}>{g.value}</option>
                                ))}
                            </select>
                            <div className="select-icon">
                                <img src={selectDown} alt="image arrow down to select" />
                            </div>
                        </div>
                        <div className="custom-select">
                            <select required id="language" onChange={(e) => setLanguage(e.target.value)}>
                                <option value=" ">{translate != null ? translate[132].value : 'Language'}</option>
                                <option value="2" >English</option>
                                <option value="1">עברית</option>
                            </select>
                            <div className="select-icon">
                                <img src={selectDown} alt="image arrow down to select" />
                            </div>
                        </div>
                        <div className="searchInput">
                            <input className="input" type="text" name="Search" id="Search" placeholder={translate != null ? translate[90].value : 'Free Search'} onChange={(e) => setSearch(e.target.value)} />
                            <img src={searchdark} alt="search icon" />
                        </div>
                        <button tabIndex={0} className="" onClick={doSearch}>{translate != null ? translate[8].value : 'Search'}</button>
                    </div>
                    <div className="line"></div>

                    <div className="books">
                        <div className="forMobileSearch">
                            <div className="dropDownSearch" id="dropDownSearch">
                                <div className="findBook" onClick={openMobileSearch}>
                                    <h3>Find a book</h3>
                                    <img id="arrow" src={arrowForward} alt="arrow down" />
                                </div>
                                <div className="searchQuery" id="searchQuery">
                                    <div className="inner">
                                        <div className="custom-select">
                                            <select name="" id="" onChange={(e) => setGenere(e.target.value)}>
                                                <option value="0">{translate != null ? translate[89].value : 'Genre'}</option>
                                                {geners.map(g => (
                                                    <option key={g.id} value={g.id}>{g.value}</option>
                                                ))}
                                            </select>
                                            <div className="select-icon">
                                                <img src={selectDown} alt="image arrow down to select" />
                                            </div>
                                        </div>
                                        <div className="custom-select">
                                            <label htmlFor="language" className="sr-only">
                                                {translate != null ? translate[132].value : 'Language'}
                                            </label>
                                            <select required id="language" onChange={(e) => setLanguage(e.target.value)}
                                                aria-label={translate != null ? translate[132].value : 'Language'}>

                                                <option value=" ">{translate != null ? translate[132].value : 'Language'}</option>
                                                <option value="2">English</option>
                                                <option value="1">עברית</option>
                                            </select>
                                            <div className="select-icon">
                                                <img src={selectDown} alt="image arrow down to select" />
                                            </div>
                                        </div>
                                        <div className="searchInput">
                                            <input className="input" type="text" name="Search" id="Search" placeholder={translate != null ? translate[90].value : 'Free Search'} onChange={(e) => setSearch(e.target.value)} />
                                            <img src={searchdark} alt="" />
                                        </div>
                                        {/* <button className="" onClick={doSearch}>
                                            {translate != null ? translate[8].value : 'Search'}
                                        </button> */}
                                        <button className="" onClick={doSearch} aria-label={translate != null ? translate[8].value : 'Search'}>
                                            {translate != null ? translate[8].value : 'Search'}
                                        </button>

                                    </div>
                                </div>
                            </div>
                            <div className="searchBooksMobile">
                                <h3>{translate != null ? translate[108].value : 'Libraries'}</h3>
                                <div className="custom-select">
                                    <select
                                        id="tabSelect"
                                        onChange={(event) => {
                                            const selectedLibraryId = event.target.value;
                                            const selectedLibraryName = Libraries.find(library => library.id === parseInt(selectedLibraryId)).libraryName;
                                            openLibrary(event, selectedLibraryId, selectedLibraryName);
                                        }}
                                    >
                                        <option value="" disabled>Select Library</option>
                                        {Libraries.map(library => (
                                            <option
                                                key={library.id}
                                                value={library.id}
                                            >
                                                {library.libraryName}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                            </div>


                            {/* <div className="searchBooksMobile">
                                <h3>Books</h3>
                                <div className="custom-select">
                                    <select id="tabSelect">
                                        {Libraries.map(library => (
                                            <option
                                                key={library.id}
                                                value={library.libraryName}
                                                className="tab"
                                                onChange={(event) => openLibrary(event,library.id, library.libraryName)}
                                            >
                                                {library.libraryName}
                                            </option>
                                        ))}
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="" />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        {/* <div> */}
                        <h1>{translate != null ? translate[108].value : 'Libraries'}</h1>
                        <div className="headerBooksList">
                            {currentLibraryIndex > 0 && (
                                <>
                                    <img src={forwardSingle} alt="arrow  Single forward" style={{ transform: 'rotateY(193deg)', marginRight: '18px' }} onClick={handlePreviousLibrary} />
                                </>
                            )}
                            {visibleLibraries.map(library => (
                                <button
                                    key={library.id}
                                    className={`tablinks ${currentLibraryIndex === library.id ? 'active' : ''}`}
                                    onClick={(event) => openLibrary(event, library.id, library.libraryName)}
                                >
                                    {library.libraryName}
                                </button>
                            ))}
                            {(currentLibraryIndex + librariesPerPage < Libraries.length) && (
                                <>
                                    <img src={forwardSingle} alt="arrow  Single forward" onClick={handleNextLibrary} />
                                    <img src={forwardDouble} alt="arrow  Double forward" onClick={handleNextLibrarySet} />
                                </>
                            )}
                        </div>

                        <div className="contentTab">
                            {Libraries.map(library => (
                                <div key={library.id} id={library.libraryName} className="tabcontent">
                                    {Array.isArray(filterBooks) &&
                                        filterBooks
                                            .filter(book => book.libraryId === library.id)
                                            .map((book, i) => (

                                                <div key={i} className="row">
                                                    <div className="left">
                                                        <img
                                                            src={book.image === '' ? bookimg : 'data:image/png;base64,' + book.image}
                                                            alt="book image"
                                                        />
                                                        <div className="span" tabIndex={0}>
                                                            <h2>{book.bookName}</h2>
                                                            <p>
                                                                {book.authorName !== 'null' ? book.authorName : ' '}  <br></br> {book.numberOfChapters} {translate != null ? translate[56].value : 'Chapters'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="center">
                                                        <p
                                                            id={i}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleTextClick(book.id)}
                                                        >
                                                            {expandedBookId === book.id ? book.summery : truncateText(book.summery, 80)}
                                                        </p>
                                                        <img src={speak} alt="Summery Audio img button " onClick={() => SummeryAudio(book.id)} />
                                                    </div>
                                                    <div className="right">
                                                        {book.continueReading && exp &&<button class="continueReading"  
                                                        
                                                         onClick={() => readMore(book.id, book.bookName, book.authorName)}
                                                         aria-label={`Continue reading ${book.bookName} by ${book.authorName}`}>Continue Reading
                                                         </button>}
                                                         {book.continueReading && !exp &&<button disabled class="continueReading"  
                                                        
                                                        onClick={() => readMore(book.id, book.bookName, book.authorName)}
                                                        aria-label={`Continue reading ${book.bookName} by ${book.authorName}`}>Continue Reading
                                                        </button>}


                                                        {!book.continueReading && exp &&
                                                         <button onClick={() => 
                                                         readMore(book.id, book.bookName, book.authorName)} 
                                                         aria-label={`Read ${book.bookName} by ${book.authorName}`}
                                                         className="read">{translate != null ? translate[131].value : 'Read'}
                                                         </button>}
                                                         {!book.continueReading && !exp &&
                                                         <button disabled onClick={() => 
                                                         readMore(book.id, book.bookName, book.authorName)} 
                                                         aria-label={`Read ${book.bookName} by ${book.authorName}`}
                                                         className="read">{translate != null ? translate[131].value : 'Read'}
                                                         </button>}
                                                        {book.continueReading && <button class="continueReading"

                                                            onClick={() => readMore(book.id, book.bookName, book.authorName)}
                                                            aria-label={`Continue reading ${book.bookName} by ${book.authorName}`}>Continue Reading
                                                        </button>}
                                                        {!book.continueReading && exp &&
                                                            <button onClick={() =>
                                                                readMore(book.id, book.bookName, book.authorName)}
                                                                aria-label={`Read ${book.bookName} by ${book.authorName}`}
                                                                className="read">{translate != null ? translate[131].value : 'Read'}
                                                            </button>}
                                                        {/* && */}
                                                        {book.libraryId === 1 && Libraries.find(l => l.id === 1 && localStorageUserId === l.userId) ? (
                                                            <div className="circle" id="circle" onClick={() => resetModal(i, library.libraryName)}>
                                                                •••
                                                                <div className="dropDownTable" style={{ display: 'none' }}>
                                                                    <div className="inner">
                                                                        <div className="square"></div>
                                                                        <button onClick={() => openEditModal(book.id)}>
                                                                            Edit book details
                                                                        </button>

                                                                        <button onClick={() => openEditChapterModal(book.id)}>
                                                                            Edit chapter details
                                                                        </button>
                                                                        <button onClick={() => openDeleteModal(book.id)}>Delete book</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            // (role != 'Student' || role != 'Officer')
                                                            book.libraryId !== 1 && book.userId == localStorageUserId && (role == 'Student' || role == 'Officer') &&
                                                            <div className="circle" id="circle" onClick={() => resetModal(i, library.libraryName)}>
                                                                •••
                                                                <div className="dropDownTable" style={{ display: 'none' }}>
                                                                    <div className="inner">
                                                                        <div className="square"></div>
                                                                        <button onClick={() => openEditModal(book.id)}>
                                                                            Edit book details
                                                                        </button>
                                                                        <button onClick={() => openEditChapterModal(book.id)}>
                                                                            Edit chapter details
                                                                        </button>
                                                                        <button onClick={() => openDeleteModal(book.id)}>Delete book</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) || (
                                                            // (role != 'Student' || role != 'Officer')
                                                            book.libraryId !== 1 && (role == 'learning central manager' || role == 'Organization Manager') &&
                                                            <div className="circle" id="circle" onClick={() => resetModal(i, library.libraryName)}>
                                                                •••
                                                                <div className="dropDownTable" style={{ display: 'none' }}>
                                                                    <div className="inner">
                                                                        <div className="square"></div>
                                                                        <button onClick={() => openEditModal(book.id)}>
                                                                            Edit book details
                                                                        </button>

                                                                        <button onClick={() => openEditChapterModal(book.id)}>
                                                                            Edit chapter details
                                                                        </button>
                                                                        <button onClick={() => openDeleteModal(book.id)}>Delete book</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                </div>
                            ))}
                            <audio controls id="carteSoudCtrl" aria-hidden="true">
                                <source src="/Files/play.mp3" type="audio/mpeg"></source>
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={handlePageChange}
                            color="primary"
                            size="small"
                            className="custom-pagination"
                        />

                    </div>
                </div>
            </div>

            {/* <!-- edit book modal start here ====== --> */}
            {/* <div className="editBookModal" id="editBookModal" dir="ltr">
            <form onSubmit={editBook}>
                <div className="innerContent">
                    <div className="headerModal">
                        <img className="cancel" onClick={closeEditModal} src={cancel} alt="" />
                        <h1>Edit Book Details</h1>
                    </div>
                    <div className="bodyModal">
                        <div className="row">
                            <input type="text" name="title" value={bookEdit.title} onChange={handleChange} placeholder="Title" />
                            <div className="custom-select">
                                <select name="library" value={bookEdit.library} onChange={handleChange}>
                                    <option value="1">Choose a library</option>
                                    <option value="2">Option 2</option>
                                    <option value="3">Option 3</option>
                                </select>
                                <div className="select-icon">
                                    <img src={selectDown} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <input type="text" name="author" value={bookEdit.author} onChange={handleChange} placeholder="Author" />
                            <div className="custom-select">
                                <select name="language" value={bookEdit.language} onChange={handleChange}>
                                    <option value="1">Language</option>
                                    <option value="2">Option 2</option>
                                    <option value="3">Option 3</option>
                                </select>
                                <div className="select-icon">
                                    <img src={selectDown} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="custom-select">
                                <select name="genre" value={bookEdit.genre} onChange={handleChange}>
                                    <option value="1">Genre</option>
                                    <option value="2">Option 2</option>
                                    <option value="3">Option 3</option>
                                </select>
                                <div className="select-icon">
                                    <img src={selectDown} alt="" />
                                </div>
                            </div>
                            <div className="custom-select">
                                <select name="narrationType" value={bookEdit.narrationType} onChange={handleChange}>
                                    <option value="1">Narration type</option>
                                    <option value="2">Option 2</option>
                                    <option value="3">Option 3</option>
                                </select>
                                <div className="select-icon">
                                    <img src={selectDown} alt="" />
                                </div>
                            </div>
                        </div>
                        <textarea name="description" value={bookEdit.description} onChange={handleChange} placeholder="Book description (will appear as a summary next to the book name)"></textarea>
                        <div className="deleteEditBook">
                            <img src={bookEdit.bookCover} alt="" />
                            <a href="#">Delete book cover</a>
                        </div>
                        <button type="submit" className="continue">Continue</button>
                    </div>
                </div>
            </form>
        </div> */}

            <div className="editBookModal" id="editBookModal" dir="ltr">
                <div className="innerContent">
                    <div className="headerModal">
                        <img className="cancel" onClick={closeEditModal} src={cancel} alt="close model" />
                        <h1>Edit Book Details</h1>
                    </div>
                    <div className="bodyModal">
                        <form action="#" onSubmit={(e) => editBook(e)}>
                            <div className="row">
                                <input type="text" name="title" id="title" placeholder="Title" value={bookEdit.title} style={{border :TitleValidation}} onChange={(e) => handleChange(e)} />
                                {/*  {(bookEdit.library !== 1 &&  */}
                                <div className="custom-select">
                                    <select value={bookEdit.library} name="library" id="library" onChange={handleChange}>
                                        <option value="1">Choose a library</option>
                                        {libararyedit.map(g => {
                                            return (
                                                <option disabled={g.id === 1 && g.userId !== localStorageUserId} value={g.id}>{g.libraryName}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                                {/*  )}*/}
                            </div>

                            <div className="row">
                                <input type="text" name="author" id="author" placeholder="Author" value={bookEdit.author} onChange={handleChange} />

                                <div className="custom-select">
                                    <select value={bookEdit.language} onChange={handleChange} name="language">
                                        <option value=" ">{translate != null ? translate[132].value : 'Language'}</option>
                                        <option value="2">English</option>
                                        <option value="1">עברית</option>
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="custom-select">
                                    <select name="genre" id="" value={bookEdit.genre} onChange={handleChange}>
                                        <option value="0">{translate != null ? translate[89].value : 'Genere'}</option>
                                        {geners.map(g => {
                                            return (
                                                <option value={g.id}>{g.value}</option>
                                            )
                                        })}
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                                <div className="custom-select">
                                    <select value={bookEdit.narrationType} name="narrationType" id="narrationType" onChange={handleChange} disabled="true">
                                        <option value=" "  >{translate != null ? translate[68].value : 'Narrator voice  '}</option>
                                        <option value="Male" >Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <div className="select-icon">
                                        <img src={selectDown} alt="image arrow down to select" />
                                    </div>
                                </div>
                            </div>

                            <textarea name="description" id="description" value={bookEdit.description} onChange={handleChange}
                                placeholder="Book description (will appear as a summary next to the book name)"></textarea>
                            <div className="deleteEditBook">
                                <img src={bookEdit.bookCover === '' ? upload1 : 'data:image/png;base64,' + bookEdit.bookCover} alt="img book cover" />
                                {/* <a href="#">Delete book cover</a> */}
                                <input type="file" id="uploadInput" onChange={(e) => onChangeHandler(e)} />
                            </div>
                            <button type="sumbit" className="continue">Update</button>
                            {errorMessage && <p style={{ color: 'red' }} className="error-message">{errorMessage}</p>}
                        </form>
                    </div>
                </div>

            </div>
            {/* <!-- edit book modal end here ====== --> */}

            {/* <!-- edit chapter modal start here  --> */}
            <div className="editChapterModal" id="editChapterModal" dir="ltr">
                <div className="innerContent">
                    <div className="headerModal">
                        <h1>Editing Chapters</h1>
                        <img onClick={closeEditChaperModal} src={cancel} alt="Close" />
                    </div>
                    <div className="bodyModal">
                        {/* {error && <div className="error">{error}</div>} */}
                        {chapters.map((chapter, index) => (
                            <div className="row" key={chapter.id}>
                                <h3>{chapter.chapterName}</h3>
                                <img
                                    src={deleteIcon}
                                    alt="Delete"
                                    onClick={() => clickShowDelete(index, chapter.id)}
                                />
                            </div>
                        ))}
                        {/* style={{ display: visibleChapters[index] ? 'block' : 'none' }} */}
                        {chapterList.map((item, index) => (
                            <div className="fileUploadCancel2" key={index} >
                                <div className="custom-upload2">
                                    <span className="file-name2" id="fileName"> chapter {startingChapterNumber + index}</span>
                                    <input
                                        type="file"
                                        className="form-control chaptername"
                                        required
                                        accept=".jpg, .jpeg, .png,.txt,.bmp,.doc,.docx,.pdf"
                                        onChange={(event) => onChangeHandlerChap(event, index)}
                                    />
                                    <button style={{ marginRight: '18px' }}>
                                        <img src={upload1} alt="upload img" required />Select File
                                    </button>
                                    <div>{fileChaptersName[index]}</div>
                                </div>
                                <img
                                    className="redCross2"
                                    onClick={() => HandlerChap(index)}
                                    style={{ marginBottom: '7px' }}
                                    src={redCross}
                                    alt="cencel chapter"
                                />
                            </div>
                        ))}
                        {msg}
                        <p className="addPara2" style={{ cursor: 'pointer', paddingBottom: '15px' }} onClick={AddChapterList}>
                            {translate != null ? translate[70].value : '+ Add chapter'} +
                        </p>
                        {showDelete && (
                            <div id="sure" className="sureToCancel">
                                <p>Are you sure you want to delete chapter {chapterIndex + 1}?</p>
                                <div className="btnsCancel">
                                    <button className="yesBtn" onClick={() => deleteChapter()} >Yes</button>
                                    <button className="cancelBtn" onClick={() => setShowDelete(false)}>Cancel</button>
                                </div>
                            </div>
                        )}
                        <button className="finish" onClick={(e) => handleSubmit(e)}>Finish</button>
                    </div>
                </div>
            </div>




            {/* <!-- delete book modal start here ====== --> */}
            <div className="deleteBookModal" id="deleteBookModal" dir="ltr">
                <div className="innerContent">
                    <div className="headerModal">
                        <img className="cancel" onClick={closeDeleteModal} src={cancel} alt="close model" />
                        <h1>Delete Book</h1>
                    </div>

                    <div className="bodyModal">
                        <p style={{ marginBottom: "19px" }}>
                            Are you sure you want to delete the book: {bookName}
                        </p>
                        <p style={{ marginBottom: '58px' }}>
                            This action will permanently delete the book, as well as delete all
                            its chapters and it is not reversible.
                        </p>
                        <div className="btns">
                            <button className="deleteBtn" onClick={deleteBook}>Delete the book</button>
                            <button className="cancelBtn" onClick={closeDeleteModal}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer lang={'en'} />
        </>
    );
}