
import React, { useState ,useEffect} from 'react';
import {useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
//import   '../assets/styles/nav/home.css';
function Footer({lang}) {
    Footer.propTypes = {
        lang: PropTypes.string.isRequired,
      };
    const navigate = useNavigate();
    const [translate,setTransate] = useState()   
    const Copyrights= ()=>{
        navigate('/Privacy');
      }
      const Contacts= ()=>{
        navigate('/Contacts');
      }
  useEffect(() => {
    if(lang === "en"){
       fetch('https://api.readupp.com/api/UserSettings/GetReadupEn')
      .then(async response => {
          const data = await response.json();
          //debugger;
          setTransate(data);
          console.log(data,"data",translate ,"translate ")
      })
      .catch(error => {
          
      });  
    }
    else{
        fetch('https://api.readupp.com/api/UserSettings/GetReadupHe')
        .then(async response => {
            const data = await response.json();
            //debugger;
            setTransate(data);
            console.log(data,"data",translate ,"translate ")
        })
        .catch(error => {
            
        });
    }
   
    

    // This ensures all event listeners and DOM manipulation occurs only once after component mount
  }, []);
    return (<>
        <div className="footer">
            <div className="inner">
            <a href="#" onClick={() => Contacts()}>{translate!=null?translate[54].value:'Contact us'}</a>
            <a href="#" onClick={() => Copyrights()}>{translate!=null?translate[83].value:'Copy Rights'}</a>
            </div>
        </div>
    </>)
}

export default Footer;

