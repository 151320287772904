import back from '../assets/icons/back.svg'
// import Footer from './Footer';

import Navrtl from './NavBar.rtl';
export default function Helprtl(){

    const Back=() =>{
        window.history.go(-1)
    }


    return( <>
     <Navrtl  nav="/help"/> 
       <img
                                class="d-none d-lg-block back pointer" style={{'width': '50px','margin-left': '3%'}}
                                src={back} onClick={() => Back()}
                                alt="חזור" />
                                
         <div class="register_container">
        <div class="register_inner_container">
            <div class="container-fluid">
                
                <div class="row">
                    <div class="col-11 col-md-12 mx-auto">
                        
                      
                        
                    <div>
                        
                        עזרה</div>
                   
                           


                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <Footer lang="he"/> */}
    </>
    )

}