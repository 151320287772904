import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import axios from "axios";
import headerWhiteLogo from '../assets/img/headerWhiteLogo.svg';
import menuICon from "../assets/img/menuIcon.svg";

import crossMenu from '../assets/img/crossMenu.svg';
import whiteDropDown from '../assets/img/whiteDropDown.svg';
import questionMark from '../assets/img/questionMark.svg';
import accessWhite from '../assets/img/accessWhite.svg';
import crossPurchase from '../assets/img/crossPurchase.svg';
import cancel from '../assets/img/cancel.svg';
import addBookStepImg from '../assets/img/addBookStepImg.svg';
import selectDown from '../assets/img/selectDown.svg';
import upload1 from '../assets/img/upload 1.svg';
import redCross from '../assets/img/redCross.svg';
import dottedLine from '../assets/img/dottedLine.svg';
import numberUp from '../assets/img/numberUp.svg';
import numberDown from '../assets/img/numberDown.svg';
import dottedLineShort from '../assets/img/dottedLineShort.svg';
import successUploadImg from '../assets/img/successUploadImg.svg';
import spinner from '../assets/img/loading-buffering.gif'; // Assuming you have a spinner image
import { useLocation } from 'react-router-dom';

//import response from '../assets/styles/global/responsive.css';

import '../assets/styles/nav/index-hebrew.css';

function Navrtl({ nav }) {

  Navrtl.propTypes = {
    nav: PropTypes.string.isRequired,
  };

  const location = useLocation();
  const role = localStorage.getItem('role');
  const orgid = localStorage.getItem('orgid');

  const navigate = useNavigate();
  const [translate, setTransate] = useState();
  const [generupload, setGenereUpload] = useState('');
  const [geners, setGeners] = useState([]);
  const [showloader, setshowloader] = useState()
  const [file, setFile] = useState()
  let   [imageLogo, setImageLogo] = useState('');
  const [filename, setFilename] = useState('');
  let   [chapterList, setChapterList] = useState([1])
  const [chaptersfile, setChaptersFile] = useState([])
  const [filechaptersname, setFileChaptersname] = useState([]);
  const [msg, setMsg] = useState('');
  const [finish, setFinish] = useState(false)
  const [text, setText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const headerClass = `header2 ${role === 'Admin' || role === 'Organization Manager' ? 'Admin' : ''}`;
  const [Libraries, setLibrary] = useState([])
  let   [period, setPeriod] = useState('Month');
  let   [numUsers, setNumUsers] = useState(0);
  const [total, setTotal] = useState('');
  const [PaymentM, setPaymentM] = useState('');
  const [PaymentY, setPaymentY] = useState('');
  const localStorageUserId = parseInt(localStorage.getItem('userId'), 10);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const closeMobileMenu = () => {
    const mobileMenuTop2 = document.getElementById("mobileMenuTop2");
    if (mobileMenuTop2 != null) {
      mobileMenuTop2.style.right = "-100%";
    }
  };

  const toggleActive = (buttonId) => {
    const button1 = document.getElementById('button1');
    const button2 = document.getElementById('button2');
    if (button1 != null) {
      button1.classList.remove('active');
    }
    if (button2 != null) {
      button2.classList.remove('active');
    }
    const activeButton = document.getElementById(buttonId);
    if (activeButton != null) {
      activeButton.classList.add('active');
    }
  };
  const purchasePopUp2 = (months, price) => {
    //debugger;
    closePaymentModal();
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';

    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=1&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }
  const purchasePopUp = () => {
    //debugger;
    let price = total
    let months = 1
    if (period == "Year") {
      months = 12;
    }
    closePaymentModal();
    document.getElementById('exampleModal').style.display = 'block';
    document.getElementById('exampleModal').style.opacity = '1';


    document.getElementById('tranzila').setAttribute('src', 'https://direct.tranzila.com/vayikra22/iframenew.php?sum=' + price + '&currency=1&email=' + localStorage.email + '&fail_url_address=https://direct.tranzila.com/vayikra22/iframenew.php&success_url_address=http://104.238.214.197:8087/success');

    localStorage.monthreq = months;
  }

  const closePurchase = () => {
    document.getElementById('exampleModal').style.display = 'none';
    document.getElementById('exampleModal').style.opacity = '0';

  }


  const openModal = () => {
    const mobileMenuTop2 = document.getElementById("mobileMenuTop2");
    const modal = document.getElementById("myModal2");
    resetModal();
    if (mobileMenuTop2 != null) {
      mobileMenuTop2.style.right = "-100%";
    }
    if (modal != null && localStorage.getItem('exp') != 'none' && localStorage.getItem('exp') != 'exp')  {
      modal.style.display = "block";
    }
  };

  const openPaymentModal = () => {
    const paymentModal = document.getElementById("paymentModal2");
    const mobileMenuTop2 = document.getElementById("mobileMenuTop2");

    if (mobileMenuTop2 != null && paymentModal != null) {
      mobileMenuTop2.style.right = "-100%";
      paymentModal.style.display = "block";
    }
  };


  useEffect(() => {
    // Check localStorage for the 'exp' value
    if (localStorage.getItem('exp') === 'none') {
      localStorage.exp='exp'
      openPaymentModal();
      
    }
  }, []); // Empty dependency array to run only on initial render



  const closePaymentModal = () => {
    const paymentModal = document.getElementById("paymentModal2");
    if (paymentModal != null) {
      paymentModal.style.display = "none";
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleDropdown();
    } else if (e.key === 'Escape' && dropdownOpen) {
      setDropdownOpen(false);
    }
  };
  const toggleDropdown = () => {
    const dropdown = document.getElementById("dropdown");
    if (dropdown != null) {
      dropdown.classList.toggle("show");
    }
  };

  const openMobileMenu = () => {
    const mobileMenuTop2 = document.getElementById("mobileMenuTop2");
    if (mobileMenuTop2 != null) {
      mobileMenuTop2.style.right = "0%";
    }
  };

  const close2Modal = () => {
    const modal = document.getElementById("myModal2");
    if (modal != null) {
      modal.style.display = "none";
    }
  };

  const nextStep = (step) => {
    const modal = document.getElementById("myModal2");
    const modalContent = modal ? modal.getElementsByClassName("modal-content2") : [];
    if (modalContent.length > step) {
      modalContent[step - 1].style.display = "none";
      modalContent[step].style.display = "block";
    }
  };

  const prevStep = (step) => {
    const modal = document.getElementById("myModal2");
    const modalContent = modal ? modal.getElementsByClassName("modal-content2") : [];
    if (step > 1 && modalContent.length >= step) {
      modalContent[step - 1].style.display = "none";
      modalContent[step - 2].style.display = "block";
    }
  };

  const showFileName = (input) => {
    const fileName = document.getElementById("fileName2");
    if (fileName != null) {
      fileName.textContent = input.files.length > 0 ? input.files[0].name : "No file chosen";
    }
  };

  const firstStep = () => {
    setFinish(false);
    setMsg('')
    resetModal();
  };

  const restartModal = () => {
    const modal = document.getElementById("myModal2");
    if (modal != null) {
      modal.style.display = "none";
    }
  };

  const resetModal = () => {
    const modal = document.getElementById("myModal2");
    const modalContent = modal ? modal.getElementsByClassName("modal-content2") : [];
    if (modalContent.length > 0) {
      Array.from(modalContent).forEach(content => content.style.display = "none");
      modalContent[0].style.display = "block";
    }
  };


  const onChangeHandler = (event) => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0])
    setFilename(event.target.files[0].name);
  }
  // const AddChapterList = () => {
  //   //debugger;
  //   chapterList.push(1);
  //   let tmp = [...chapterList];
  //   setChapterList(tmp)


  // }
  const [fileChaptersName, setFileChaptersName] = useState([]);
  const [visibleChapters, setVisibleChapters] = useState([]);

  const AddChapterList = () => {
    const newChapterList = [...chapterList, chapterList.length + 1];
    const newVisibleChapters = [...visibleChapters, true]; // Add visibility state for the new chapter
    setChapterList(newChapterList);
    setVisibleChapters(newVisibleChapters);
  };

  const onChangeHandlerChap = (event, index) => {
    //debugger;
    if (document.getElementsByClassName('chaptername').length > 0 && event.target != undefined && event.target.files[0] != undefined) {


      const files = [...fileChaptersName];
      files[index] = event.target.files[0].name;
      setFileChaptersName(files);

      chaptersfile.push(event.target.files[0]);
      let tmp = [...chaptersfile];
      setChaptersFile(tmp)

      // console.log(event.target.files[0]);
      // chaptersfile.push(event.target.files[0]);
      // let tmp = [...chaptersfile];
      // setChaptersFile(tmp)

      // filechaptersname.push(event.target.files[0].name);
      // let tmpchapters = [...filechaptersname];
      // setFileChaptersname(tmpchapters);

    }

  }
  const HandlerChap = (index) => {
    const newChapterList = chaptersfile.filter((_, i) => i !== index);
    const newFileChaptersName = fileChaptersName.filter((_, i) => i !== index);
    const newVisibleChapters = visibleChapters.filter((_, i) => i !== index);
    setChaptersFile(newChapterList);
    setChapterList(newChapterList)

    setFileChaptersName(newFileChaptersName);
    setVisibleChapters(newVisibleChapters);
  };
  const handleSubmit = async () => {
    if (finish) {
      close2Modal();
    }
    try {
      const bookname = document.getElementById('modal-body2');
      const chapters = document.getElementsByClassName('chaptername');
      const formData = new FormData();
      let chapterStr = '';


      let lang = document.getElementById('language').value 

      let name = "";
     
      if(lang== "2"){
       name = "chapter ";
      }
      else{
          name = "פרק ";
      }

      for (let i = 0; i < fileChaptersName.length; i++) {
        chapterStr += name +  (i + 1) + ';';
      }

      formData.append('bookName', document.getElementById('title').value || "");
      formData.append('chaptersNames', chapterStr || "");
      formData.append('bookFile', file || ""); // Ensure 'file' has a default value
      formData.append('userId', localStorage.userId);
      formData.append('library', document.getElementById('library').value || "");
      formData.append('author', document.getElementById('author').value || ""); // Default to "0" if empty
      formData.append('language', document.getElementById('language').value || "");
      formData.append('style', document.getElementById('style').value || "0"); // Default to "0" if empty
      formData.append('voice', document.getElementById('voice').value || "");
      formData.append('description', document.getElementById('description').value || ""); // Default to "" if empty
      formData.append('onlyChapters', false);
      chaptersfile.forEach((file, i) => formData.append(`chaptersFiles`, file)); // Ensure 'chaptersfile' is defined

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      setLoading(true);
      setMsg('העיבוד יסתיים בעוד מספר דקות, ולאחריו הספר יהיה זמין לקריאה כעת אנחנו מעבדים את הספר ');
      setshowloader(true);

      const url = 'https://api.readupp.com/api/Books/UploadFiles';

      const response = await axios.post(url, formData, config);
      // Start loading spinner
      if (response.status === 200) {
        setshowloader(false);
        setMsg(' ההעלאה הסתיימה בהצלחה!');
        setFinish(true);

        document.getElementById('author').value = '';
        document.getElementById('title').value = '';
        document.getElementById('description').value = '';
        document.getElementById('language').value = ' ';
        document.getElementById('style').value = 0;
        document.getElementById('voice').value = '0';
        document.getElementById('library').value = 0;
        setFilename('');
        setFile(undefined);
        chaptersfile.length = 0;
        //setChaptersFile([]);
        setFileChaptersname([]);
        chapterStr = '';

      } else {
        setMsg('An unexpected error occurred.');
      }
    } catch (error) {
      setshowloader(false);

      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        if (error.response.status === 500) {
          setMsg('Server error: 500 Internal Server Error.');
        } else {
          setMsg(`Server error: ${error.response.status}`);
        }
      } else if (error.request) {
        // The request was made but no response was received
        setMsg('No response received from server.');
      } else {
        // Something happened in setting up the request that triggered an Error
        setMsg('Error: ' + error.message);
      }
    }

    finally {
      setLoading(false); // Stop loading spinner
    }

  }

  const handleContinue = (e, step) => {
    e.preventDefault();
    if (validateForm(step)) {
      nextStep(step);
    }
  };

  const validateForm = (step) => {
    const title = document.getElementById('title');
    const library = document.getElementById('library');
    const author = document.getElementById('author');
    const language = document.getElementById('language');
    const style = document.getElementById('style');
    const voice = document.getElementById('voice');
    const description = document.getElementById('description');
    const file = document.getElementById('uploadInput')
    // Reset error borders

    title.classList.remove('error-border');
    library.classList.remove('error-border');
    author.classList.remove('error-border');
    language.classList.remove('error-border');
    style.classList.remove('error-border');
    voice.classList.remove('error-border');
    description.classList.remove('error-border');

    let isValid = true;

    if (step === 2) {
      if (!title.value) {
        title.classList.add('error-border');
        isValid = false;
      }
      if (library.value === '0') {
        library.classList.add('error-border');
        isValid = false;
      }
      // if (!author.value) {
      //   author.classList.add('error-border');
      //   isValid = false;
      // }
      if (language.value === ' ') {
        language.classList.add('error-border');
        isValid = false;
      }
      // if (style.value === '0') {
      //   style.classList.add('error-border');
      //   isValid = false;
      // }
      if (voice.value === '0') {
        voice.classList.add('error-border');
        isValid = false;
      }
      // if (!description.value) {
      //   description.classList.add('error-border');
      //   isValid = false;
      // } if (!file.value) {
      //   file.classList.add('error-border');
      //   isValid = false;
      // }
    } else if (step === 3) {
      if (file === null) {

        isValid = false;
      }
      if (chaptersfile.length === 0) {

        isValid = false;
      }
      // if (!file.value) {
      //   file.classList.add('error-border');
      //   isValid = false;
      // }
    }

    if (!isValid) {
      setErrorMessage('שדות חובה אנא מלא .');
    } else {
      setErrorMessage('');
    }

    return isValid;
  };
  const periodMap = {
    'Month': 'חודש',
    'Year': 'שנה'
  }

  const Logout = () => {
    localStorage.removeItem('loginDate')
    navigate('/loginrtl');
  }
  const changePeriod = (value) => {


    setPeriod(value)
    if (value == "Month") {
      const parsedUsers = parseInt(numUsers);

      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.key.split('-')[1]);

        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon
          const value = parseFloat(group.value.split(';')[0]);
          setTotal(parsedUsers * value);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }
    else {
      const parsedUsers = parseInt(numUsers);

      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.key.split('-')[1]);

        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon
          const value = parseFloat(group.value.split(';')[0]);
          setTotal(parsedUsers * value * 12);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }
  }
  const changeNumUsers = (value) => {
    setNumUsers(value)
    if (period == "Month") {
      const parsedUsers = parseInt(value);

      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.key.split('-')[1]);

        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon parseFloat()
          const value = group.value.split(';')[0];
          setTotal(parsedUsers * value);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }
    else {
      const parsedUsers = parseInt(value);

      for (let group of PaymentM) {
        // Extract the upper limit from the key
        const upperLimit = parseInt(group.key.split('-')[1]);

        if (parsedUsers < upperLimit) {
          // Use the first value before the semicolon
          parseFloat()
          const value = group.value.split(';')[0];
          setTotal(parsedUsers * value * 12);
          break; // Exit the loop once a condition is satisfied
        }
      }
    }


  }

  const upDown = (isIncrease) => {
    let newValue = isIncrease ? numUsers + 1 : Math.max(numUsers - 1, 0);
    changeNumUsers(newValue);
  };

  useEffect(() => {

    fetch('https://api.readupp.com/getAllLibrarys?Id=' + localStorage.userId)
      //  fetch('https://localhost:44318/getAllLibrarys?Id=' + localStorage.userId)
      .then(async response => {
        const data = await response.json();
        // setLibrary(data);

        if (Array.isArray(data)) {
          if (role !== 'Admin' && role !== 'Organization Manager' && role !== "learning central manager") {
            const libraryFilter = data.filter(library => {
              const libraryUserId = parseInt(library.userId, 10);
              console.log('Library userId:', libraryUserId, 'LocalStorage userId:', localStorageUserId);
              return libraryUserId === localStorageUserId && library.visibility === 1;
            });
            console.log('Filtered libraries:', libraryFilter);
            setLibrary(libraryFilter);
          }
          else {
            setLibrary
              (data)
          }
        }
      })
      .catch(error => {
        console.error('There was an error fetching user settings!', error);
      });
    fetch('https://api.readupp.com/Login/GetOrg?userid=' + localStorage.userId + '&orgid=-1')
      .then(async response => {
        //debugger;
        const data = await response.json();
        if (data.logo) {
          setImageLogo("https://vayikra-bible.com/" + data.logo)
        }
        else {
          setImageLogo(' ');
        }
      })
      .catch(error => {

        console.error('There was an error!', error);
      });
    if (role === 'Admin' || role === 'Organization Manager') {

      fetch(`https://api.readupp.com/Login/GetOrganizationPayment?organizaitonid=${localStorage.orgid}`)
        // fetch(`https://localhost:44318/Login/GetOrganizationPayment?organizaitonid=${localStorage.orgid}`)
        .then(async response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setPaymentM(data);

        })
        .catch(error => {
          console.error('There was an error fetching the payment data:', error);
        });
    }
    else {
      fetch(`https://api.readupp.com/Login/GetUserPayment?userid=${localStorageUserId}`)
        //fetch(`https://localhost:44318/Login/GetUserPayment?userid=${localStorageUserId}`)
        .then(async response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          let month = data.filter(x => x.eng_key === 'Monthly');
          let year = data.filter(x => x.eng_key === 'Yearly');

          const monthlyValue = month.length > 0 ? month[0].value.split(';')[0] : '';
          const yearlyValue = year.length > 0 ? year[0].value.split(';')[0] : '';

          // Update state with the extracted values
          setPaymentM({ ...month[0], value: monthlyValue });
          setPaymentY({ ...year[0], value: yearlyValue });

          // setPaymentM(month[0]);
          // setPaymentY(year[0]);
          console.log('Monthly Payment:', PaymentM);
          console.log('Yearly Payment:', PaymentY);
        })
        .catch(error => {
          console.error('There was an error fetching the payment data:', error);
        });
    }

    const fetchData = async () => {
      try {
        const [readupHeResponse, genresResponse] = await Promise.all([
          fetch('https://api.readupp.com/api/UserSettings/GetReadupHe'),
          fetch('https://api.readupp.com/api/Genre/GetGenres'),
        ]);

        const readupHeData = await readupHeResponse.json();
        setTransate(readupHeData);

        const genresData = await genresResponse.json();
        setGeners(genresData);

        localStorage.loginDate = Date.now();
        setText('העלאת תמונת כריכת ספר');

      } catch (error) {
        console.error('There was an error!', error);
      }
    };

    fetchData();
    setChapterList(chapterList);
    setshowloader(false);

  }, []);

  return (
    // 
    <div dir='rtl'>
      <div className="mobileMenuTop2" id="mobileMenuTop2">
        <div className="inner2">
          <div className="headerText2">
            <img className="logo2" onClick={closeMobileMenu} src={crossMenu} alt="סגור תפריט" />
            <div className="switchBtn2">
              <a href={nav} id="button1" onClick={() => toggleActive('button1')} className="eng"> Eng</a>
              <a href="#" id="button2" onClick={() => toggleActive('button2')} className="hebrew active"> עברית</a>
            </div>
          </div>
          <ul>
            {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
              <li><a className={location.pathname === '/Usersrtl' ? 'nav-link' : ' '} href="./Usersrtl">{translate != null ? translate[108].value : 'Users'}</a></li>)}
            {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
              <li><a className={location.pathname === '/Libaryrtl' ? 'nav-link' : ' '} href="./Libaryrtl">{translate != null ? translate[107].value : 'Libraries'}</a></li>)}
            <li><a className={location.pathname === '/booksrtl' ? 'nav-link' : ' '} href="./booksrtl">{translate != null ? translate[111].value : ' Books'}</a></li>
            {location.exp!='exp' && location.exp!='none' && <li><a href="#" onClick={openModal}>{translate != null ? translate[110].value : 'Upload a Book'}  </a></li>}
            <li><a className={location.pathname === '/accountrtl' ? 'nav-link' : ' '} href="./accountrtl">{translate != null ? translate[109].value : 'My Account'}</a></li>
            <li><a onClick={() => Logout()}>{translate != null ? translate[12].value : 'Log Out'} </a></li>
            {(((orgid != 1 && (role !== 'Organization Manager' && role !== 'Admin'))) && (<li><a href="#" style={{ display: 'none' }}>{translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
            {((orgid == 1 || role === 'Organization Manager' || role === 'Admin') && (<li className="upgrade2"><a href="#" onClick={openPaymentModal}>{translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
          </ul>
          <ul>
            <li><a href="#">{translate != null ? translate[114].value : 'Accessibility'}</a></li>
            <li><a href="#">{translate != null ? translate[115].value : 'Support'}</a></li>
            <li><a href="#">{translate != null ? translate[116].value : 'Terms of Use'}</a></li>
            <li><a href="#">{translate != null ? translate[117].value : 'Privacy Policy'}</a></li>
          </ul>
        </div>
      </div>
      {/*className={role === 'Admin' || role === 'Organization Manager' ? 'header2Admin':'header2'}  */}
      <div className={headerClass}>
        <div className="left2">
          <img src={menuICon} className="mobileMenu2" onClick={openMobileMenu} alt="פתח תפריט" />
          <a href="./booksrtl" className="logo2">
            <img src={headerWhiteLogo} alt="תמונת כותרת" />
          </a>
          <div className="overLay2">
            <ul style={{ marginTop: '70px;' }} >
              {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
                <li><a className={location.pathname === '/Usersrtl' ? 'nav-link' : ' '} href="./Usersrtl">{translate != null ? translate[108].value : 'Users'}</a></li>)}
              {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") && (
                <li><a className={location.pathname === '/Libaryrtl' ? 'nav-link' : ' '} href="./Libaryrtl">{translate != null ? translate[107].value : 'Libraries'}</a></li>)}
              <li><a className={location.pathname === '/booksrtl' ? 'nav-link' : ' '} href="./booksrtl">{translate != null ? translate[111].value : ' Books'}</a></li>
              <li><a href="#" onClick={openModal}>{translate != null ? translate[110].value : 'Upload a Book'} </a></li>
              <li><a className={location.pathname === '/accountrtl' ? 'nav-link' : ' '} href="./accountrtl">{translate != null ? translate[109].value : 'Libraries'}</a></li>
              <li><a href='' onClick={() => Logout()}>{translate != null ? translate[12].value : 'Log Out'} </a></li>
              {(((orgid != 1 && (role !== 'Organization Manager' && role !== 'Admin')) )&& ( <li><a href="#"  style={{display:'none'}}>{ translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
              {((orgid == 1 || role === 'Organization Manager' || role === 'Admin') && ( <li><a href="#" onClick={openPaymentModal} >{ translate != null ? translate[112].value : 'Upgrade'}</a></li>))}
            </ul>
          </div>
        </div>
        <div className="right2">
          <div className="lang" id="myDropdown" onClick={toggleDropdown}
            onKeyDown={handleKeyDown}
            tabIndex={0}
            aria-haspopup="true"
            aria-expanded={dropdownOpen}>
            <p>עברית</p>
            <img src={whiteDropDown} alt="חץ לבחירה" />
            <div className="dropdown" id="dropdown">
              <a href={nav}>English</a>
              {/*  */}
            </div>
          </div>
          <a className="icons" href="#"><img src={accessWhite} alt="נגישות" /></a>
          <a className="icons" href="/help"><img src={questionMark} alt="עזרה" /></a>
          {/* {(role === 'Admin' || role === 'Organization Manager' || role === "learning central manager") || orgid !== 1  && (  )} */}
            {imageLogo != ' ' && <div className="logoInst">
              <img className="instituteLogo" src={imageLogo} alt="Institute Logo" />
            </div>}
        
        </div>
      </div>
      {/* <div className="mainContent bookMobileColor"> */}



      {/* Payment Modal */}
      {(role === 'Admin' || role === 'Organization Manager') && (
        <div className="paymentModal2" dir='rtl' id="paymentModal2">
          <div className="innerContent2">
            <img
              onClick={closePaymentModal}
              className="crossPurchase2"
              src={crossPurchase}
              alt="סגור מודל תשלום"
            />
            <h1>כדי לבצע את הפעולה יש לרכוש מנוי:</h1>
            <p>
              מנוי מאפשר לכם תרגול חופשי בכל הספרים הכלולים בספריית Readup ובנוסף
              העלאת ספרים משלכם באופן עצמאי
            </p>

            <div className="userCards2">
              {Array.isArray(PaymentM) && PaymentM.map(payment => {
                const [firstValue] = payment.value.split(';'); // Get the first number before ';'
                const [firstkey] = payment.key.split(';');
                return (
                  <div className="card2" key={payment.id}>
                    <p>{firstkey} משתמשים</p>
                    <h6>{firstValue} ₪ למשתמש</h6> {/* Display only the first value */}
                    <img src={dottedLine} alt="קו" />
                  </div>
                );
              })}
              {/* <div className="card2">
                <p>100-500 Users</p>
                <h6>6 NIS/User</h6>
                <img src={dottedLine} alt="" />
              </div>
              <div className="card2">
                <p>500-1000 Users</p>
                <h6>5 NIS/User</h6>
                <img src={dottedLine} alt="" />
              </div>
              <div className="card2">
                <p>1000+ Users</p>
                <h6>Contact Us</h6>
              </div> */}
            </div>
            <form action="#" >
              <div className="left2">
                <p>מספר המשתמשים הנדרש:</p>
                <div className="number-input-container2" >
                  <input id="customNumberInput" min="0" type="number" style={{ textAlign: 'right' }} placeholder="" value={numUsers} onChange={(e) => changeNumUsers(e.target.value)} />
                  {/* <input type="number" min="0" id="customNumberInput" value="0"> */}
                  {/* <input type="number" min="0" id="customNumberInput" defaultValue="0" /> */}
                  <div className="custom-buttons2" >
                    <button type="button" onClick={() => upDown(true)} className="increase">
                      <img src={numberUp} alt="העלה מספר" />
                    </button>
                    <button type="button" onClick={() => upDown(false)} className="decrease">
                      <img src={numberDown} alt="הורד מספר" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="right2">
                <p>לתקופה של:</p>

                <div className="custom-select2">
                  <div className="select-icon2">
                    <img src={selectDown} alt="" />
                  </div>  <select value={period} name="period" id="period" onChange={(e) => changePeriod(e.target.value)}>
                    <option value="Month">חודש</option>
                    <option value="Year">שנה</option>
                  </select>

                </div>
              </div>
            </form>

            <div className="total2">
              <div className="leftt2">
                <h6 style={{ marginRight: '31px' }}> סיכום</h6>
                <h3 className="firstH32    ">{numUsers} משתמשים</h3>

                <h6>ל  {periodMap[period]}</h6>
              </div>
              <img style={{ marginRight: '35px' }} src={dottedLineShort} alt="קו" />
              <div className="rightt2">
                <h3>סה”כ: {total} ₪</h3>
              </div>
            </div>
            <button className="purchaseBtn2" onClick={() => purchasePopUp()}>רכישה</button>
          </div>
        </div>)}
      {(role !== 'Admin' || role !== 'Organization Manager') && (
        <div className="paymentModal2" id="paymentModal2">
          <div className="innerContent2">
            <img
              onClick={closePaymentModal}
              className="crossPurchase2"
              src={crossPurchase}
              alt="סגור מודל"
            />
            <h1>כדי לבצע את הפעולה יש לרכוש מנוי:</h1>

            <p>
              מנוי מאפשר לכם תרגול חופשי בכל הספרים הכלולים בספריית Readup ובנוסף העלאת ספרים משלכם באופן עצמאי
            </p>

            <div className="twoOffer2">

              <div className="monthly2">
              
                <h3>

                  מנוי       <br />{PaymentM.key}

                </h3>

                <h5 style={{ marginBottom: '19px' }}>₪{PaymentM.value} /{PaymentM.key}</h5>
                <p style={{ marginBottom: '39px' }}>מתחדש אוטומטית </p>

               
                <p style={{ marginBottom: '15px' }}>קריאה בלתי מוגבלת

                </p>
                <div className="purchaseBtn2">
                  <button onClick={() => purchasePopUp2(PaymentM.key,PaymentM.value )}>רכישה</button>
                </div>
              </div>
              <div   className="yearly2" >
              <div className="bestValue2">
                  <p>הערך הטוב ביותר

                  </p>
                </div>
                <h3>
                  מנוי  <br /> {PaymentY.key}

                </h3>
                <h5 style={{ marginBottom: '19px' }}>₪{PaymentY.value} / {PaymentY.key}</h5>
              
                <p style={{ marginBottom: '39px' }}>בתשלום מראש </p>
               
                <p style={{ marginBottom: '15px' }}>קריאה בלתי מוגבלת

                </p>
                <div className="purchaseBtn2">
                  <button onClick={() => purchasePopUp2( PaymentY.key,PaymentY.value)} >רכישה</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

      }

      {/* </div>  */}


      <div id="myModal2" className="addBookModal2">
        {/* Step 1 */}
        <div className="modal-content2">
          <div className="modal-header2">
            <span className="close2" onClick={close2Modal}>
              <img src={cancel} alt="סגור" /></span>
            <h2>{translate ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body2">
            <p style={{ marginBottom: '20px' }}>
              באפשרותכם להוסיף ידנית כל ספר או קובץ טקסט שתרצו, וקריין אוטומטי
              יקריא לכם אותו. אין מגבלה על סוג הספר או הטקסט.
            </p>
            <p>
              כדי להתחיל בתהליך ההעלאה חשוב שיהיו לכם פרקי הטקסט באחד הפורמטים
              הבאים:
            </p>
            <p style={{ paddingLeft: '10px' }}>• ניתן להעלות קובץ טקסט .docx.</p>
            <p style={{ paddingLeft: '10px' }}>
              • במידה ואין לכם קובץ טקסט, ניתן להעלות סריקה של פרק, ולהעלות כ JPG
              או PDF
            </p>
            <img src={addBookStepImg} alt="הוסף תמונה" />
            <button onClick={() => nextStep(1)} className="step1Btn2">{translate ? translate[10].value : 'Continue'}</button>
          </div>
        </div>
        {/* Step 2 */}
        <div className="modal-content2" id="step2" style={{ display: 'none' }}>
          <div className="modal-header2">
            <span className="close2" onClick={close2Modal}><img src={cancel} alt="סגור מודל" /></span>
            <h2>{translate ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body2">
            <h2 className="headerTitle2">1 | פרטי הספר</h2>
            <p className="headerPara2">
              ראשית, מלאו את פרטי הספר, בחרו את הספריה אליה תרצו להעלות את הספר,
              ובחרו את סוג הקריינות:            </p>

            <form action="#" id="demo_form">
              {/* {loading && <img src={spinner} alt="Loading..." className="spinner" />} Spinner image */}
              <div className="row2">
                <input type="text" id="title" placeholder={translate != null ? translate[73].value : ' Book name  '} />
                <div className="custom-select2">
                  <select class="input__field" required name="library" id="library" onChange={(e) => setGenereUpload(e.target.value)}>
                    <option value="0">בחר/י ספריה </option>
                    {Libraries.map(g => {
                      return (
                        <option disabled={g.id === 1 && g.userId !== localStorageUserId} value={g.id}>{g.libraryName}</option>
                      )
                    })}
                  </select>
                  <div className="select-icon2">
                    <img src={selectDown} alt="חץ לבחירה למטה" />                                  
                  </div>
                </div>
              </div>

              <div className="row2">
                <input type="text" id="author" placeholder={translate != null ? translate[79].value : ' Author   '} />
                <div className="custom-select2">
                  <select class="input__field" required id="language">
                    <option value=" " >{translate != null ? translate[55].value : 'Language'}</option>
                    <option value="2">English</option>
                    <option value="1">עברית</option>
                  </select>
                  <div className="select-icon2">
                  <img src={selectDown} alt="חץ לבחירה למטה" /> 
                  </div>
                </div>
              </div>

              <div className="row2">
                <div className="custom-select2">

                  <select class="input__field" required name="" id="style" onChange={(e) => setGenereUpload(e.target.value)}>
                    <option value="0">זאנר</option>
                    {geners.map(g => {
                      return (
                        <option value={g.id}>{g.value}</option>
                      )
                    })}
                  </select>
                  <div className="select-icon2">
                  <img src={selectDown} alt="חץ לבחירה למטה" /> 
                  </div>
                </div>
                <div className="custom-select2">
                  <select id='voice' required class="input__field" >
                    <option value="0"  >{translate != null ? translate[68].value : 'Narrator voice  '}</option>
                    <option value="Male" >Male</option>
                    <option value="Female">Female</option>
                  </select>
                  <div className="select-icon2">
                  <img src={selectDown} alt="חץ לבחירה למטה" /> 
                  </div>
                </div>
              </div>

              <textarea id="description" required placeholder="תיאור הספר (יופיע כתקציר ליד שם הספר)"></textarea>
              <div className="custom-upload2">

                <span className="file-name" style={{ margin: '7px' }} onChange={(e) => showFileName(e.target)} id="fileName2">
                  {finish ? text : ''}

                  {filename != '' ? filename : translate && translate[77] ? translate[77].value : 'Upload picture'}
                </span>

                <button required>
                  <input type="file" id="uploadInput" required onChange={(e) => onChangeHandler(e)} />
                  <img src={upload1} alt="העאלה תמונה" required />העלאה
                </button>
              </div>
              <div className="modal-footer2">
                {/* <button onClick={() => prevStep(2)} className="back2">{translate != null ? translate[104].value : ' back  '}</button> */}
                <button onClick={(e) => handleContinue(e, 2)} className="continueBtn2">{translate != null ? translate[10].value : ' Continue  '}</button>
              </div>
              {errorMessage && <p style={{ color: 'red' }} className="error-message">{errorMessage}</p>}
            </form>

          </div>
        </div>
        {/* Step 3 */}
        <div className="modal-content2" id="step3" style={{ display: 'none' }}>
          <div className="modal-header2">
            <span className="close2" onClick={close2Modal}>
              <img src={cancel} alt="סגור" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body2">
            <h2 className="headerTitle2">2 | העלאת קבצי הפרקים</h2>
            <p className="headerPara2">
              כעת טענו למערכת את הפרקים שסרקתם: <br />
              ניתן להעלות למערכת קבצי PDF, JPG או DOCX
            </p>
            {chapterList.map((item, index) => {
              return (

                //  style={{ display: visibleChapters[index] ? 'block' : 'none' }}
                <div className="fileUploadCancel2">
                  <div className="custom-upload2">
                    <span style={{ marginLeft: '12px' }} className="file-name2" id="fileName"> פרק  {index + 1}</span>
                    {/* <div class="file-input"> */}
                    <input type="file" class="form-control chaptername" required accept=".jpg, .jpeg, .png,.txt,.bmp,.doc,.docx,.pdf" onChange={(e) => onChangeHandlerChap(e, index)} />
                    <button style={{ marginright2: '18px' }}>
                      <img src={upload1} alt="העאלה קובץ" required />העלאת קובץ
                    </button>
                    <div>{fileChaptersName[index]}</div>
                    {/* <p>{translate!=null?translate[71].value:'Upload file'}</p> */}
                    {/* </div> */}

                  </div>
                  <img className="redCross2" onClick={() => HandlerChap(index)} style={{ marginBottom: '7px' }} src={redCross} alt="בטל קובץ" />
                </div>
              )
            })}
            {/* <div className="fileUploadCancel2">
              <div className="custom-upload2">
                <span className="file-name2" id="fileName"> פרק 1</span>
                <input type="file" id="uploadInput" onChange={(e) => showFileName(e.target)} />
                <button style={{ marginright2: '18px' }}>
                  <img src={upload1} alt="" />Select File
                </button>
              </div>
              <img className="redCross2" style={{ marginBottom: '7px' }} src={redCross} alt="" />
            </div> */}

            {/* <div className="fileUploadCancel2">
              <div className="custom-upload2">
                <span className="file-name2" id="fileName">פרק 2</span>
                <input type="file" id="uploadInput" onChange={(e) => showFileName(e.target)} />
                <button style={{ marginright2: '18px' }}>
                  <img src={upload1} alt="" />Select File
                </button>
              </div>
              <img className="redCross2" style={{ marginBottom: '7px' }} src={redCross} alt="" />
            </div> */}

            <p className="addPara2" onClick={() => AddChapterList()}>{translate != null ? translate[70].value : '+ Add chapter'} +</p>
            <div className="modal-footer2">
              <button onClick={() => prevStep(3)} className="back2">{translate != null ? translate[104].value : ' back  '}</button>
              <button onClick={(e) => handleContinue(e, 3)} className="continueBtn2">{translate != null ? translate[10].value : ' Continue  '}</button>
            </div>
            {errorMessage && <p style={{ color: 'red' }} className="error-message">{errorMessage}</p>}
          </div>
        </div>
        {/* Step 4 */}
        <div className="modal-content2" id="step4" style={{ display: 'none' }}>
          <div className="modal-header2">
            <span className="close2" onClick={close2Modal}>
              <img src={cancel} alt="סגור" />
            </span>
            <h2>{translate != null ? translate[51].value : 'Add your text'}</h2>
          </div>
          <div className="modal-body2">
            <h2 className="headerTitle2">    {finish ? msg : ' '} </h2>
            <p className="headerPara2">
              {!finish ? msg : ''}

            </p>
            <img className="successUpload2" src={successUploadImg} alt="תמונת הצלחה" />
            <div className="modal-footer2">
              <button onClick={firstStep} style={{ width: '200px' }} className="back2">
                {translate != null ? translate[106].value : ' Upload another book  '}
              </button>
              <button onClick={() => handleSubmit()} className="continueBtn2">{translate != null ? translate[105].value : ' Finish '}</button>
            </div>
          </div>
        </div>
      </div>

      {/* </div> */}

      <div style={{ display: 'none' }} class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog subs_modal">
          <div class="modal-content" style={{ 'margin-top': '20%' }}>
            <div class="container-fluid rtl" >

              <div class="w-100">

                <iframe id="tranzila" src="https://direct.tranzila.com/vayikra22/iframenew.php?sum=1&fail_url_address=http://google.com&notify_url_address=http://google.com">
                </iframe>

                <button class="px-4 py-2 text-white bg_color1 rounded-3 border-0 mt-5" id="closeiframe" onClick={() => closePurchase()}>
                  Close
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>







    </div>
  );
}

export default Navrtl;
